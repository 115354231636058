import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
import CreditCardImage from 'components/CreditCardImage';
import ServiceError from 'components/Errors/ServiceError';
import Modal from 'components/Modal';
import {BRANDS} from 'components/PaymentMethod';
import {
    CreditCardBrand,
    PhoneNumberInput,
    ReservationDetailDocument,
    ReservationDetailQuery,
    ReservationFragment,
    ReservationStatus,
    ReserveUserProfileFragment,
} from 'gql/generated';
import {capitalize} from 'lodash';
import {toMY} from 'utils/date';
import {toFormattedPhoneNumber} from 'utils/phone';
import {commify} from 'utils/string';
import {DetailsSkeleton} from './DetailsSkeleton';

type DetailsHeaderProps = {
    className?: string;
    label: string;
};

export const DetailsHeader: FC<DetailsHeaderProps> = ({className, label}) => (
    <h3
        className={`border-outline col-span-full border-b pb-2 pt-3 font-semibold ${className}`}
    >
        {label}
    </h3>
);

type DetailsBlockProps = {
    className?: string;
    label: string;
    privacyLevel?: 'allow' | 'mask';
    value: string;
} & Partial<HTMLDivElement>;

export const DetailsBlock: FC<DetailsBlockProps> = ({
    className,
    label,
    privacyLevel = 'mask',
    value,
}) => (
    <div className={className}>
        <div className="text-sub text-xs">{label}</div>
        <div className="text-sm font-semibold" data-dd-privacy={privacyLevel}>
            {value}
        </div>
    </div>
);

type DetailsProps = {
    isModal: boolean;
    onClose: () => void;
    reservation: ReservationFragment;
    user: ReserveUserProfileFragment;
};

enum ReservationType {
    realtime = 'realTimeBooking',
    request = 'reservationRequest',
    waitlist = 'waitlist',
}

const getReservationType = (reservation: ReservationFragment) => {
    if (reservation.waitingListRequest) {
        return ReservationType.waitlist;
    }

    if (reservation.status === ReservationStatus.Pending) {
        return ReservationType.request;
    }

    return ReservationType.realtime;
};

export const Details: FC<DetailsProps> = ({
    isModal,
    onClose,
    reservation,
    user,
}) => {
    const {t} = useTranslation();

    const {id, status, venue} = reservation;

    const {data, error, loading} = useQuery<ReservationDetailQuery>(
        ReservationDetailDocument,
        {
            variables: {id},
        }
    );

    if (error) {
        if (!isModal) {
            return <ServiceError className="col-span-full" error={error} />;
        }

        return (
            <Modal onClose={onClose}>
                <ServiceError error={error} />
            </Modal>
        );
    }

    if (loading || !data?.reservation) {
        if (!isModal) {
            return <DetailsSkeleton className="col-span-full" />;
        }

        return (
            <Modal onClose={onClose}>
                <DetailsSkeleton />
            </Modal>
        );
    }

    const {
        allergy,
        course,
        hotelGuestName,
        hotelName,
        id: reservationId,
        memo,
        onBehalfOfGuest,
        paymentMethod,
        receipt,
        seatingType,
        specialRequestOption,
        specialRequestOptionMessage,
        visitFrequency,
        visitPurpose,
    } = data.reservation;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {__typename, cardBrand, cardExpiryMonth, cardExpiryYear, cardLast4} =
        paymentMethod as {
            __typename?: 'CreditCard' | undefined;
            cardBrand: CreditCardBrand;
            cardExpiryMonth: number;
            cardExpiryYear: number;
            cardLast4: string;
            id: string;
        };
    const expires = toMY(new Date(cardExpiryYear, cardExpiryMonth - 1));

    const firstName = onBehalfOfGuest
        ? onBehalfOfGuest.firstName
        : user.firstName;
    const firstNameKana = onBehalfOfGuest
        ? onBehalfOfGuest.firstNameKana
        : user.firstNameKana;
    const lastName = onBehalfOfGuest ? onBehalfOfGuest.lastName : user.lastName;
    const lastNameKana = onBehalfOfGuest
        ? onBehalfOfGuest.lastNameKana
        : user.lastNameKana;
    const phoneNumber = onBehalfOfGuest
        ? onBehalfOfGuest.phoneNumber
        : user.phoneNumber;
    const email = onBehalfOfGuest ? onBehalfOfGuest.email : user.email;

    const reservationType = getReservationType(reservation);

    const detailsTitle = t(`reserve.details.title.${reservationType}`);

    const idLabel = t(`reserve.details.id.${reservationType}`);

    const panel = (
        <div className="grid grid-cols-1 gap-3 sm:pb-3 md:grid-cols-2">
            <DetailsHeader className="hidden sm:block" label={detailsTitle} />
            <DetailsBlock
                className="mt-4 sm:mt-0"
                label={t('venue.tabs.courses.course')}
                privacyLevel="allow"
                value={`${course.name}`}
            />
            <DetailsBlock
                className="mt-4 sm:mt-0"
                label={idLabel}
                privacyLevel="allow"
                value={reservationId}
            />
            {seatingType && (
                <DetailsBlock
                    label={t('seatingType.label')}
                    privacyLevel="allow"
                    value={t(`seatingType.${seatingType}`)}
                />
            )}
            <DetailsBlock
                label={t('reserve.details.visitPurpose.label')}
                privacyLevel="allow"
                value={t(`reserve.details.visitPurpose.${visitPurpose}`)}
            />
            <DetailsBlock
                label={t('reserve.details.visitFrequency.summary', venue)}
                privacyLevel="allow"
                value={t(`reserve.details.visitFrequency.${visitFrequency}`)}
            />
            {hotelName && (
                <DetailsBlock
                    label={t('reserve.details.hotel.name')}
                    value={hotelName}
                />
            )}
            {hotelGuestName && (
                <DetailsBlock
                    label={t('reserve.details.hotel.guest')}
                    value={hotelGuestName}
                />
            )}
            {specialRequestOption?.title && (
                <DetailsBlock
                    className="col-span-full"
                    label={t('reserve.details.specialRequestOption.label')}
                    value={specialRequestOption.title}
                />
            )}
            {specialRequestOptionMessage && (
                <DetailsBlock
                    className="col-span-full"
                    label={t('reserve.details.specialRequestOption.message')}
                    value={specialRequestOptionMessage}
                />
            )}
            {allergy && (
                <DetailsBlock
                    className="col-span-full"
                    label={t('reserve.details.allergy')}
                    value={allergy}
                />
            )}
            {status !== ReservationStatus.Pending && memo && (
                <DetailsBlock
                    className="col-span-full"
                    label={t('reserve.details.memo')}
                    value={memo}
                />
            )}
            <DetailsHeader label={t('reserve.payment.title')} />
            {__typename === 'CreditCard' && (
                <div className="flex items-center">
                    <CreditCardImage size="lg" type={cardBrand} />
                    <div className="text-sub ml-4 text-xs">
                        <span>
                            {t('user.paymentMethods.cardEndingIn', {
                                brand:
                                    BRANDS[cardBrand] ||
                                    cardBrand
                                        .split('_')
                                        .map(capitalize)
                                        .join(' '),
                                cardLast4,
                            })}
                        </span>
                        <br />
                        <span>
                            {t('user.paymentMethods.expires', {
                                expires,
                            })}
                        </span>
                    </div>
                </div>
            )}
            <DetailsHeader label={t('user.reservations.details.order')} />
            <div className="col-span-full grid grid-cols-12 text-grey-700 dark:text-grey-200">
                <div className="col-span-8 text-xs">
                    {t('user.reservations.details.statement')}
                </div>
                <div className="col-span-2 flex justify-center text-xs">
                    {t('user.reservations.details.quantity')}
                </div>
                <div className="col-span-2 flex justify-center text-xs">
                    {t('user.reservations.details.total')}
                </div>
                {receipt?.receiptItems?.map((item) => {
                    const {description, quantity, total} = item;

                    return (
                        <div
                            key={description}
                            className="col-span-full grid grid-cols-12"
                        >
                            <div className="col-span-8">{description}</div>
                            <div className="col-span-2 flex justify-center text-sm">
                                {quantity}
                            </div>
                            <div className="col-span-2 flex justify-center text-sm">
                                ¥{total && commify(total)}
                            </div>
                        </div>
                    );
                })}
            </div>
            <hr className="col-span-full -mt-2 md:hidden" />
            <div className="col-span-full grid grid-cols-12 text-sm font-semibold">
                <div className="col-span-10 text-right">
                    {t('user.reservations.details.totalAmount')}
                </div>
                <div className="col-span-2 flex justify-center">
                    ¥{receipt?.total && commify(receipt?.total)}
                </div>
            </div>
            <DetailsHeader label={t('reserve.details.contactInformation')} />
            {onBehalfOfGuest && (
                <h4 className="col-span-full text-sm">
                    {t('user.reservations.bookedOnBehalfOf')}
                </h4>
            )}
            <DetailsBlock
                className={clsx(!firstNameKana && 'col-span-full')}
                label={t('name')}
                value={t('user.fullName', {
                    firstName,
                    lastName,
                })}
            />
            {firstNameKana && (
                <DetailsBlock
                    label={t('user.basicInformation.name.furigana')}
                    value={t('user.fullName', {
                        firstName: firstNameKana,
                        lastName: lastNameKana,
                    })}
                />
            )}
            <DetailsBlock
                label={t('user.basicInformation.phoneNumber.label')}
                value={toFormattedPhoneNumber(phoneNumber as PhoneNumberInput)}
            />
            <DetailsBlock
                label={t('user.basicInformation.email')}
                value={email as string}
            />
        </div>
    );

    if (isModal) {
        return (
            <Modal onClose={onClose} title={detailsTitle}>
                {panel}
            </Modal>
        );
    }

    return <div className="col-span-full">{panel}</div>;
};
