export default {
    authentication: {
        forgotPassword: {
            message:
                'パスワードをリセットするためのリンクを記載したメールをお送りします。',
            success:
                'パスワードをリセットするためのリンクが記載されたメールが送信されました。',
            title: 'パスワードを忘れた場合',
        },
        login: {
            linkToForgotPassword: 'パスワードを忘れた場合はこちら',
            linkToResendConfirmation: '確認メールが届かない方はこちら',
            linkToSignup: '新規登録',
            title: 'ログイン',
        },
        logout: 'ログアウト',
        resendConfirmation: {
            message:
                '再度確認メールをお送りいたします。登録したメールアドレスをご入力ください。',
            success: 'メールをご確認の上、確認リンクをクリックしてください。',
            title: '確認メールが届きませんでした',
        },
        resetPassword: {
            message: '新しいパスワードを入力してください。',
            success: 'パスワードが更新されました',
            title: 'パスワードの再設定',
        },
        signup: {
            buttonTitle: '新規登録',
            linkToLogin: 'すでに会員の方はこちら',
            privacyAndTerms:
                '<privacy>プライバシーポリシー</privacy>と<terms>利用規約</terms>に同意します<required>必須</required>',
            privacyRequired: '受入が必要です',
            receiveNewsletter: 'メールマガジンを受け取る',
            title: '新規登録',
        },
        signupConfirmation: {
            error: `アカウントは既に認証されている、またはトークンが正しくありません。<br/><br/>ご質問などがありましたら<a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>までご連絡ください。`,
            noTokenMessage:
                'ご登録されたメールアドレスに本登録手続きのメールを送信しました。<br/>メールに記載のURLにアクセスいただきますと、会員登録が完了いたします。<br/><br/>Gmailをご利用のお客様へ：<br/>本登録用のメールが迷惑メールに振り分けられる場合がございますので、受信フォルダに併せて、迷惑メールのフォルダもご確認ください。',
            success: '会員登録が完了しました',
            title: '仮登録を受け付けました',
        },
        toBookmark: '空席通知の登録は会員登録／ログイン後に行えます。',
        toReserve:
            '予約をするには、ログインするか、新しいアカウントを登録してください。',
        unlockAccount: {
            error: `アカウントのロックは既に解除されている、またはトークンが正しくありません。<br/><br/>ご質問などがありましたら<a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>までご連絡ください。`,
            success: 'アカウントのロックが削除されました。',
            title: 'アカウントのロック削除',
        },
    },
    back: '戻る',
    bannerNotice: '年末年始の営業について',
    bookmarks: {
        add: '空席通知に登録する',
        addVenue: '{{- name}}を空席通知に登録する',
        label: '空席通知に登録する',
        none: 'お店が登録されていません',
        remove: '空席通知を解除する',
        removed: '空席通知を解除しました',
        successfullyAdded: '空席通知を登録しました',
        title: '空席のお知らせ',
    },
    contactForm: {
        chanceToKnow: 'PocketConciergeを知ったきっかけ',
        collaboration: {
            title: '協業について',
        },
        companyName: '会社名',
        department: '部署名',
        email: 'メールアドレス',
        faq: 'よくある質問',
        inquiry: 'お問い合わせ',
        introduction: {
            title: 'レストランのご紹介について',
        },
        message: '本文',
        name: '氏名',
        nameKana: '氏名（フリガナ）',
        newsletter: {
            changeEmail: 'メールアドレスの変更について',
            newsletter: 'メールマガジンについて',
            title: 'メールマガジン',
            unsubscribe: '配信停止について',
        },
        owner: {
            title: 'レストランのご紹介について',
        },
        payment: {
            authorization: '与信確認について',
            onlineAdvancePayment: '事前決済について',
            paymentMethods: '決済方法について',
            refunds: '返金について',
            title: '決済について',
        },
        phoneNumber: '電話番号',
        privacyPolicy: '<Link>個人情報</Link>の取り扱いについて同意する',
        publication: {
            title: '掲載依頼について',
        },
        receipt: {
            title: '領収書の確認方法について',
        },
        registration: {
            changeEmail: 'メールアドレスの変更について',
            changePassword: 'パスワードの変更について',
            deleteAccount: '退会について',
            registration: '会員登録方法について',
            registrationEmail: 'ご登録状態について',
            registrationStatus: '本登録用確認メールついて',
            title: '会員登録について',
        },
        reservations: {
            changeBookingDetailsCancel: '予約内容の変更・キャンセルについて',
            howToBook: '予約方法について',
            reservationCancellation: 'キャンセルポリシーについて',
            reservationConfirmation: '確定メールについて',
            title: '予約について',
        },
        restaurantName: '店舗名',
        restaurantWebUrl: '会社または店舗のHPのアドレス',
        restaurants: {
            allergy: 'アレルギー対応について',
            anniversaryRequests: '記念日対応について',
            availableTimes: '受入可能時間について',
            children: 'お子様について',
            childrenTopics: {
                kidsAge: '年齢について',
                kidsAllergy: 'アレルギー・苦手について',
                kidsMenu: '食事の有無について',
            },
            courseDetails: 'コース内容について',
            numberOfGuests: '予約可能人数について',
            seatTypes: '座席指定について',
            title: '店舗情報について',
        },
        submit: '送信',
        title: 'お問合せ',
        topic: 'ご用件',
        webUrl: 'WebサイトURL',
    },
    date: {
        day: '日',
        month: '月',
        year: '年',
    },
    details: '詳細',
    error: {
        404: {
            message: '404: リクエストされたURLにはコンテンツがありません。',
            title: 'コンテンツが見当たりません',
        },
        500: {
            message: '500: 予期せぬエラーが発生しました。',
            title: 'サーバーエラー',
        },
        emailInvalid: '有効な電子メールを入力してください',
        noSecretSeats:
            '指定された空席のリンクは現在無効です。<br/><br/>コンシェルジュに<Link>お問い合わせ</Link>ください。',
        passwordIncorrect: '現在のパスワードが正しくない',
        sessionExpired: 'セッションが切れました。ログインをしてください',
        threeDSecure: 'クレジットカードが認証されませんでした',
        unexpected: `予期せぬエラーが発生した。 もう一度やり直してください。<br/>エラーが続く場合は、<a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a> にメールしてください。`,
    },
    errorCodes: {
        accountDeletionError: '食事の予約がある場合は退会できません',
        authenticationError: 'ログインしてください',
        creditCardError: 'クレジットカードが認証されませんでした',
        creditCardRemovalError:
            'このクレジットカードは来店前の予約に使われているため、削除が出来ません',
        emailTakenError: '有効なメールアドレスをお使いください',
        emailUpdateError: 'ご登録のメールアドレスは変更できませんでした',
        genericError: 'エラーが発生しました',
        invalidCourse: 'コースやデータの取得エラーが発生しました',
        invalidReservationForMessageError:
            'ウェイティングリストリクエストの場合はメッセージの送信ができません',
        notUniqueError:
            'クレジットカードがもう保存されています。別のカードをご利用ください',
        passwordUpdateError: 'パスワードは変更できませんでした',
        questionnaireStatusError: 'ご意見はもうシェアしたことがあります',
        questionnaireSubmitError: '現在、ご意見のシェアができません',
        reservationCancelError: 'キャンセルの処理が失敗しました',
        reservationGenericError:
            'エラーが発生しましたので、予約が取れませんでした',
        reservationIneligibleBenefitsCardError:
            '対象のアメリカン・エキスプレスのクレジットカードを使ってください',
        reservationIneligibleBenefitsUserError:
            '対象のアメリカン・エキスプレスのクレジットカードを使ってください',
        reservationStatusError: 'キャンセルの処理が失敗しました',
        reservationUnavailableError: 'ご希望の席は満席になりました',
        resetPassword: {
            notFoundError: 'トークンが無効です',
            tokenExpirationError: 'トークンの有効期限が切れています',
            userInputError: 'トークンは正しくありません',
        },
        serverError: 'サーバーエラーが発生しました',
        traceId: 'エラーID：{{traceId}}',
        userError: 'メールアドレスまたはパスワードが正しくありません',
        userInputError: {
            birthday:
                '生年月日が正しく入力されてないようなので入力内容をご確認ください',
            company_name:
                '会社名が正しく入力されてないようなので入力内容をご確認ください',
            email: 'メールアドレスが正しく入力されてないようなので入力内容をご確認ください',
            firstname:
                '名が正しく入力されてないようなので入力内容をご確認ください',
            firstname_kana: 'ふりがな(名)',
            lastname:
                '姓が正しく入力されてないようなので入力内容をご確認ください',
            lastname_kana: 'ふりがな(姓)',
            password:
                'パスワードが正しく入力されてないようなので入力内容をご確認ください',
            tel: '退会理由が正しく入力されてないようなので入力内容をご確認ください',
            unknown:
                '必要な情報が正しく入力されてないようなので入力内容をご確認ください',
            user_resign_reasons:
                '退会理由が正しく入力されてないようなので入力内容をご確認ください',
        },
        userNewReservationsForbidden:
            '現在ご予約が制限されております。<br/><Link>問い合わせ窓口</Link>より管理者にお問い合わせください。',
        waitlistDuplicatedError:
            '同日のウェイティングリストリクエストができません',
    },
    form: {
        clear: 'クリア',
        fieldIsRequired: '{{label}}は必須項目です',
        no: 'いいえ',
        pleaseWait: 'お待ちください',
        required: '必須',
        save: '保存する',
        select: '選択',
        selectDate: '日付の選択',
        submit: '送信',
        thisFieldIsRequired: 'これは必須項目です。',
        yes: 'はい',
    },
    home: {
        firstRow: '',
        makeReservationsThroughPocket:
            '厳選レストランの検索・予約・決済は<br/>ポケットコンシェルジュ',
        newArrivals: '新着レストラン',
        pickUpVenues: 'ピックアップレストラン',
        secondRow: '',
    },
    imageCarousel: {
        imageName: '{{name}}画像{{n}}',
        nextImage: '次の画像',
        previousImage: '前の画像',
    },
    name: '名前',
    pagination: {
        firstPage: '最初のページ',
        lastPage: '最後のページ',
        nextPage: '次のページ',
        page: '{{page}}ページ',
        prevPage: '前のページ',
        reservation_one: 'ご予約{{count}}件中　{{start}}-{{end}}件',
        reservation_other: 'ご予約{{count}}件中　{{start}}-{{end}}件',
        restaurant_one: 'レストラン{{count}}件中　{{start}}-{{end}}件',
        restaurant_other: 'レストラン{{count}}件中　{{start}}-{{end}}件',
    },
    readLess: '記事を折りたたむ',
    readMore: '記事を展開',
    realTimeBooking: '即時予約',
    redirectCountdown:
        "<a href='/'>クリックしてポケットコンシェルジュに移動します。</a><br/><br/>{{seconds}}秒で自動的にリダイレクト...",
    redirecting: 'リダイレクト中',
    reservation: {
        costPerGroupCurrency: '¥{{costPerGroup}} / 組',
        costPerGuest: '1人あたり',
        costPerGuestCurrency: '¥{{costPerGuest}} / 人',
        guestCount_one: '{{count}}名様',
        guestCount_other: '{{count}}名様',
        taxSurcharge: '（税金/追加料金）',
        timeOfArrival: '来店可能時間帯',
        totalAmount: '合計金額（税サ込）',
    },
    reservationRequest: '予約申込',
    reserve: {
        confirmation: {
            makeNewReservation: '新規予約',
            realTimeBooking: {
                case1: '店舗のルールを守っていただけない場合',
                case2: 'アレルギーや食品制限への対応が難しい場合',
                case3: 'その他、天災を除く何らかの不測の事情により、お客様の受け入れが困難な場合',
                contact:
                    'また、予約時にご登録いただいた連絡先が無効な場合、お店の判断により予約がキャンセルとなることがあります。',
                importantInformation: '予約に関する重要な情報',
                liability:
                    '尚、確定後のご予約キャンセルにより不利益が生じた場合、弊社及び店舗では責任を負いかねます。予めご理解・ご了承ください。',
                smartPayment:
                    '当日レストランで会計をすることなく、<Link>スマート決済</Link>を通し自動でカード決済を行います',
                title: 'オンライン決済を承り、予約が確定されました',
                understand:
                    '以下に該当する場合、確定後のご予約をキャンセルとさせていただく場合がございます。',
            },
            reservationRequest: {
                importantInformation: '予約申込に関する重要な情報',
                liability:
                    'ご予約は、本サービス上のユーザー様個人ページにおいて、「予約完了」である旨が表示された時点で成立します。別途予約完了メールを送信いたしますが、こちらはあくまでも確認の目的でお送りするものですので、必ずご予約後にユーザー様の個人ページのご確認をお願い致します。「メールが届いていない」あるいは「メールを確認していない」等の理由により生じた損失に関しては補償いたしかねます。',
                noEmail: `迷惑メールフォルダもご確認いただき、万一届いていない場合、お手数ですが<a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>までご連絡ください。`,
                notFinalized:
                    '店舗にてご予約の確認が完了次第、結果をご連絡させていただきます。',
                receiveEmail:
                    'この申請後すぐにご登録されたメールアドレス宛に確認のメールが届きます。',
                title: '予約申込を承りました',
            },
            waitlist: {
                cancellations:
                    '当日キャンセルに付きましては、ドリンク代含めたコース料金100%をキャンセル料金として頂戴しますので、予めご了承ください。',
                deadline: '待機指定日',
                importantInformation: 'ウェイティングリストに関する重要な情報',
                onceTableOpens:
                    '“待機指定日“までに席が出ましたら、自動的に「<span>予約確定</span>」となります。',
                title: 'ウェイティングリスト登録が完了しました。',
            },
        },
        details: {
            allergy: 'アレルギー',
            allergyMultiLabel: 'アレルギー <<ul><li>アレルギーをお持ちの方がわかるように記載してください。</li><li>アレルギーの詳細や度合いをできるだけ詳しくお知らせください。内容によってはお受けできない場合がございます。</li></ul>',
            allergyExample: '例）本人が中程度の甲殻類アレルギー。出汁も避けてください。',
            contactInformation: '連絡先',
            hotel: {
                guest: 'Name of guest',
                label: 'Are you staying in a hotel?',
                name: 'Hotel name',
            },
            id: {
                realTimeBooking: '予約ID',
                reservationRequest: '予約申込ID',
                waitlist: 'ウェイティングリストID',
            },
            memo: 'お店へのご要望',
            onBehalfOf: {
                anotherPerson: '別の方が来店',
                label: 'ご来店者はだれですか？',
                myself: '本人',
            },
            preferences: 'ご要望（任意)',
            specialRequestOption: {
                label: '記念日のご用意について',
                message: 'メッセージ内容',
                unselected: '特に必要なし',
            },
            submit: '次へ',
            supplementaryInformation: '補足情報',
            title: {
                realTimeBooking: '即時予約詳細',
                reservationRequest: '予約申込詳細',
                waitlist: 'ウェイティングリスト詳細',
            },
            visitFrequency: {
                FIRST_VISIT: '初回',
                SECOND_VISIT: '2回目',
                THIRD_OR_MORE_VISIT: '3回以上',
                label: '{{- name}}のご来店は何回目ですか？',
                summary: '来店',
            },
            visitPurpose: {
                ANNIVERSARY: '記念日',
                CLIENT_DINNER: '仕事で接待',
                DATE: 'デート',
                FAMILY: '家族や親戚',
                JOB_FRIEND_DINNER: '仕事仲間と食事',
                MEAL_WITH_FRIEND: '友達と食事',
                OTHER: 'その他',
                label: '会食の趣旨',
            },
        },
        payment: {
            onlinePayment: 'オンラインクレジットカード決済',
            onlinePaymentRequired:
                '予約時にクレジットカードが必要です。これにより、支払いプロバイダーに承認リクエストを行うことができます。',
            required: 'お支払い方法を選択してください',
            ryoshusho: {
                label: '領収書',
                placeholder: '領収書の宛名（任意）',
            },
            selectPaymentMethod: 'クレジットカードを選択する',
            submit: '次へ',
            title: '支払詳細',
        },
        summary: {
            about: 'この予約申請について',
            agreeToTerms:
                "上記のお願い及び注意点、<a href='/terms'>利用規約</a>に同意します。",
            checkReservationStatus:
                '<span>ご予約は、本サービス上のユーザー様個人ページにおいて、「予約完了」である旨が表示された時点で成立します。</span><br />別途予約完了メールを送信いたしますが、こちらはあくまでも確認の目的でお送りするものですので、必ずご予約後にユーザー様の個人ページのご確認をお願い致します。「メールが届いていない」あるいは「メールを確認していない」等の理由により生じた損失に関しては補償いたしかねます。',
            noEmail: `迷惑メールフォルダもご確認いただき、万一届いていない場合、お手数ですが<a href='mailto:${process.env.GUEST_MAIL}'>${process.env.GUEST_MAIL}</a>までご連絡ください。`,
            notGuaranteed:
                'こちらは予約の申請であり、お席の確保を保証するものではございません。ご予約申請後、店舗にてご予約の確認が完了次第、来店可否のご連絡をさせていただきます。',
            realTimeBooking: {
                submit: '予約申請する',
                title: '即時予約内容確認',
            },
            receiveEmail:
                'ご予約申請後すぐに、ご登録されたメールアドレス宛に確認のメールが届きます。',
            reservationPolicy: 'お店からのお願い',
            reservationRequest: {
                submit: '予約申請する',
                title: '予約申請内容確認',
            },
            waitlist: {
                about: 'ウェイティングリストについて',
                checkReservationStatus:
                    'ご予約は、本サービス上のユーザー様個人ページにおいて、「予約完了」である旨が表示された時点で成立します。',
                confirmReservation:
                    'ご申請いただいた待機指定日までに希望店舗にてキャンセルが出た場合は、ご予約が確定となります',
                confirmationEmail:
                    '別途予約完了メールを送信いたしますが、こちらはあくまでも確認の目的でお送りするものですので、必ずご予約後にユーザー様の個人ページのご確認をお願い致します。「メールが届いていない」あるいは「メールを確認していない」等の理由により生じた損失に関しては補償いたしかねます。',
                submit: 'ウェイティングリストに登録する',
                title: 'ウェイティングリスト内容確認',
            },
        },
    },
    returnToTop: 'Topページへ戻る',
    search: {
        advancedSearch: '高度な検索',
        allDistricts: '{{prefecture}}全域',
        clear: 'すべて削除します',
        dineIn: '店内で',
        keywordResults: 'キーワード',
        label: '検索',
        noRestaurantsFound:
            '検索条件に一致するレストランは見つかりませんでした。',
        placeholderKeyword: 'レストラン、キーワード',
        priceRange: '価格帯',
        priceRanges: {
            '1': '¥10,000以下',
            '2': '¥10,000 - ¥20,000',
            '3': '¥20,000 - ¥30,000',
            '4': '¥30,000以上',
        },
        realTimeBooking: '即時予約',
        restaurants: 'レストラン',
        searchByNameOrKeyword: '名前やキーワードで検索',
        seatsAvailable: '利用可能な座席',
        selectADate: '日付の選択',
        selectArea: 'エリア',
        selectCuisine: 'ジャンル',
        selectDate: '日付の選択',
        selectGuests: '人数',
        selectSeating: '座席指定',
        selectServiceType: '時間',
        selectServiceTypeWaitlist: '食事',
        useCurrentLocation: '現在地を使用',
        waitlistOnly: 'ウェイティングリスト',
    },
    seatingType: {
        COUNTER: 'カウンター',
        NONE: '指定なし',
        PRIVATE_COUNTER: 'カウンター個室',
        PRIVATE_ROOM: '個室',
        TABLE: 'テーブル',
        TAKEOUT: 'テイクアウト',
        label: '座席指定',
    },
    select: '選択',
    selectLanguage: '言語を選択します',
    serviceTypes: {
        DINNER: 'ディナー',
        LUNCH: 'ランチ',
    },
    siteName: 'Pocket Concierge',
    staticPages: {
        companyProfile: '会社概要',
        contact: 'お問い合わせ',
        contactVenue: '掲載依頼',
        faq: 'よくある質問',
        privacyPolicy: 'プライバシーポリシー',
        termsOfUse: '利用規約',
        transaction: '特定商取引法に基づく表記',
    },
    theme: {
        darkMode: 'ダークモード',
        enableDarkMode: 'ダークモードを有効にします',
        enableLightMode: 'ライトモードを有効にします',
        lightMode: 'ライトモード',
    },
    underConstruction: '建設中',
    user: {
        basicInformation: {
            companyName: '会社名',
            dob: '生年月日',
            email: 'メールアドレス',
            gender: {
                female: '女性',
                label: '性別',
                male: '男性',
                preferNotToSay: '回答しない',
            },
            name: {
                first: {
                    kana: 'かな（名）',
                    label: '名',
                    placeholder: '名',
                },
                furigana: 'ふりがな',
                last: {
                    kana: 'かな（姓）',
                    label: '姓',
                    placeholder: '姓',
                },
            },
            phoneNumber: {
                countryCode: '国コード',
                invalid: '電話番号が無効です',
                label: '電話番号',
            },
            title: '基本情報',
            updated: 'プロフィールが更新されました',
        },
        changeEmail: {
            currentEmail: '現在のメールアドレス',
            newEmail: '新規のメールアドレス',
            success: '確認のメールの送信先：',
            title: 'メールアドレスを変更する',
            toastMessage:
                'ご変更されたメールアドレスに確認メールを送信しました',
            warning: '電子メールは同じ',
        },
        changePassword: {
            currentPassword: '現在のパスワード',
            newPassword: '新しいパスワード',
            title: 'パスワードを変更する',
            updated: 'パスワードが変更できました',
        },
        deleteAccount: {
            BAD_RESPONSE: '対応が悪いから',
            HARD_TO_USE_SERVICE: 'サービスが使いづらいから',
            LIFESTYLE_MISMATCH: 'ライフスタイルに合わないから',
            NO_ATTRACTIVE_RESTAURANTS: '魅力的な店舗がないから',
            NO_CAMPAIGN: 'お得なキャンペーンがないから',
            OTHER: 'その他',
            confirmation: 'アカウントを完全に削除する',
            farewell: 'アカウントを削除させていただきました。',
            note: '説明文をご記入ください。',
            reasonRequired: '理由を1つ以上お選びください。',
            reasons: 'ご退会の理由をお聞かせいただけませんか。',
            title: '退会',
            warning:
                '<p>ポケットコンシェルジュのサービスを退会します。</p>退会手続き完了後:<ul><li>全ての個人情報はポケットコンシェルジュのサービス上から削除されます。なお、一度削除された個人情報を復元することはサービス運営会社にもできません。</li><li>退会手続きの完了後、退会前にご利用いただいていたメールアドレスを用いて再度会員登録いただくことが可能となります。ただし、同じメールアドレスをご利用いただく場合であっても、過去の予約履歴等を再登録いただいたアカウントに移行することはできません。</li><li>ポケットコンシェルジュからのメールマガジン配信は停止されます。（メールマガジンの配信停止のみをご希望の場合は<Link>こちら</Link>をクリックしてください）</li></ul>退会の理由をお聞かせ下さい。',
        },
        emailPreferences: {
            featuredMailNotification:
                '受け取る<br/><span>※お店ページで「空席情報のお知らせを受ける」をチェックした場合にお送りするお知らせメール</span>',
            featuredMailNotificationTitle:
                '「空席通知に登録する」を押したレストランの空席のお知らせ',
            newsletter:
                '受け取る<br/><span>※システム反映まで10日程メールマガジンが届く可能性がございます</span>',
            newsletterTitle: 'メールマガジン',
            title: 'メールマガジン設定',
            updated: 'メールマガジンの設定を変更できました',
        },
        foodPreferences: {
            allergy: 'アレルギー',
            dislikedFood: '嫌いな食べ物',
            favoriteFood: '好きな食べ物',
            title: '食べ物の好み',
            updated: '食の好みを更新しました',
        },
        fullName: '{{lastName}} {{firstName}}',
        password: {
            confirm: 'パスワードの確認',
            input: 'パスワード',
            lowercase: '小文字1文字以上',
            min: '8文字以上',
            mismatch: 'パスワードが一致しない',
            number: '数字一つ以上',
            showHide: 'パスワードの表示/非表示',
            uppercase: '大文字1文字以上',
        },
        paymentMethods: {
            acceptedCards:
                'ポケットコンシェルジュの受け入れるカードはこちらです',
            addNewCard: '新しいカードを追加する',
            cardEndingIn: '****{{cardLast4}}',
            cardList: 'カード表示',
            cardNumber: 'カード番号',
            expirationDate: '有効期限',
            expired: '期限切れ {{- expires}}',
            expires: '有効期限 {{- expires}}',
            nameIsRequired: '名前が必要です',
            nameOnCard: 'カード名義',
            noCards: 'カードはまだ登録していません',
            removeCard: '削除',
            saveCard: 'カードを保存',
            securityCode: 'セキュリティコード',
            threeDButtonTitle: '認証する',
            threeDSubTitle:
                'オンライン決済を安心してご利用いただく為に、本人認証を行います。',
            threeDTitle: '本人認証が必要です。',
            title: 'お支払い方法',
        },
        profile: 'プロフィール',
        reservations: {
            bookedOnBehalfOf: '代理での予約',
            button: {
                back: '戻る',
                cancel: 'キャンセル',
                cancelReservation: '予約をキャンセルする',
                inquiry: '変更・問い合わせ',
                invoice: '領収書',
                withdraw: '登録取消',
            },
            cancelDayBeforeInformation:
                'ご予約についてのキャンセル、お問い合わせ、変更は予約前日の18時以降は直接お電話にてご連絡をお願い致します。電話番号: {{tel}}',
            cancelReason: 'キャンセルの理由',
            cancelReservation: '予約キャンセル',
            cancelReservationCancel: 'キャンセルしない',
            cancellation: {
                confirmation: 'ご予約のキャンセル申請を受け付けました',
                fee: 'キャンセル料： ¥{{amount}} （{{percentage}}％）',
                notice: 'キャンセルポリシーは<Link>こちら</Link>よりご確認ください',
                reason: '予約をキャンセルしてよろしいですか？',
                subTitle: 'このご予約をキャンセルしますか？',
                subTitleWaitlist:
                    'ウェイティングリストをキャンセルします。よろしいですか？',
                title: 'ご予約のキャンセル',
                titleWaitlist: '登録取消',
            },
            details: {
                order: 'ご注文明細',
                price: '金額',
                quantity: '数量',
                statement: '明細項目',
                total: '小計',
                totalAmount: '合計金額（税サ込）',
            },
            filters: {
                aria: 'フィルター',
                cancellations: 'キャンセル',
                completed: '完成品',
                confirmed: '確認済み',
                past: '過去',
                pending: '保留',
            },
            hideReservationDetails: '詳細を表示',
            inquiry: {
                subTitle: 'レストランがメールにて回答いたします',
                title: 'ご予約変更',
            },
            noReservations: 'まだ予約はしていません。',
            noReservationsFiltered:
                '選択されたフィルターに一致する予約はありません。',
            payment: '支払い',
            paymentDate: '支払日',
            questionnaire: {
                header: 'ご意見をお聞かせください。',
                label: '簡単な感想をお願いします。',
                rating: 'お食事の満足度はいかがでしたか？',
                satisfactionRating: {
                    DISSATISFIED: 'あまり満足していない',
                    SATISFIED: '満足',
                    VERY_DISSATISFIED: '満足していない',
                    VERY_SATISFIED: '非常に満足',
                },
                success: 'ご感想をお寄せいただきありがとうございました。',
            },
            reservationStatus: '予約状況',
            selection: {
                all: '全て',
                canceled: 'キャンセル',
                completed: '完了',
                past: '過去',
                pending: '確認中',
                reserved: '予約済',
                upcoming: '本日以降',
            },
            sendMessage:
                '内容の確認、変更希望、ご質問は下記のフォームよりお問い合わせください。',
            sendMessageSuccess: 'お客様のご要望はレストランに送信されました。',
            showReservationDetails: 'ご予約の詳細',
            status: {
                CANCELLATION_CONFIRMED: {
                    label: 'キャンセル完了',
                },
                CANCELLATION_REQUESTED: {
                    label: 'キャンセル確認中',
                    message:
                        'お客様のキャンセル依頼を確認しています。しばらくお待ちください。',
                },
                CONFIRMED: {
                    label: '予約確定',
                    message: '予約が確定しました。',
                },
                DECLINED: {
                    label: '不成立',
                    message: 'ご予約は成立しませんでした。',
                },
                NO_SHOW: {
                    label: 'ノーショー',
                    message: 'キャンセルポリシーにより、料金は返金されません。',
                },
                PENDING: {
                    reservation: {
                        label: '予約確認中',
                        message:
                            'お店にご予約内容を確認しています。しばらくお待ちください。',
                    },
                    waitlist: {
                        label: 'ウェイティングリスト',
                    },
                },
                SAME_DAY_CANCELLATION: {
                    label: '当日キャンセル',
                },
                VISITED: {
                    label: '来店',
                    message: 'ご来店ありがとうございました。',
                },
                WAITLIST_PERIOD_EXPIRED: {
                    label: 'ウェイティングリスト',
                    message: 'ウェイティングリストはキャンセルされました。',
                },
            },
            title: 'ご予約履歴',
            waitlist: 'ウェイティングリスト',
            waitlistDeadline: '待機指定日',
            withdrawRequest: '撤退要求',
            withdrawRequestCancel: '要求を撤回しないこと',
            withdrawRequestConfirm:
                '本当にキャンセル待ちの申請を取り下げますか？',
        },
        settings: '設定',
        welcome: 'ようこそ',
    },
    venue: {
        address: '{{prefecture}}{{city}}{{town}}{{street}}',
        addressHidden: 'ご予約確定時に住所をお知らせいたします。',
        businessHours: '営業時間',
        cuisine: 'ジャンル',
        dateOfVisit: '訪問日：',
        faq: 'FAQ',
        holidays: '定休日',
        name: 'レストラン名',
        noRecommendationsAvailable: 'レビューはまだありません',
        noSeats: '現在ご予約いただける日程がございません',
        numberOfVisits: {
            FIRST_VISIT: '初回',
            SECOND_VISIT: '2回目',
            THIRD_OR_MORE_VISIT: '3回以上',
            label: '訪問回数：',
        },
        purposeOfVisit: {
            ANNIVERSARY: '記念日',
            CLIENT_DINNER: '仕事で接待',
            DATE: 'デート',
            EXTERNAL_SYSTEM_RESERVATION: 'Amexの予約サービスご利用',
            FAMILY: '家族や親戚',
            JOB_FRIEND_DINNER: '仕事仲間と食事',
            MEAL_WITH_FRIEND: '友達と食事',
            OTHER: 'その他',
            label: '訪問の目的:',
        },
        reservation: '予約',
        secretSeat: {
            expirationText:
                'この席の有効期間は<br/>{{date}}の{{time}}までです。',
            title: '限定空席',
        },
        services: {
            anniversaryServices: '記念日サービス',
            childFriendly: '子供に優しい',
            childrenOver12Accepted: '12歳以上の子供',
            englishMenu: '英語メニュー',
            englishSpeakingStaff: '英語スタッフ',
            partyReservations: 'パーティー予約',
            privateRooms: 'プライベートルーム',
            smokingArea: '喫煙エリア',
            taxiAvailable: 'タクシー利用可能',
        },
        tabs: {
            access: {
                label: 'アクセス',
            },
            courses: {
                confirmReservation: '予約を確認する',
                confirmWaitlist: 'ウェイティングリストの登録を確認する',
                course: 'コース',
                label: 'コース',
                noCoursesAvailable:
                    '{{date}}にご予約できるお席がございません。',
                selectTime: '時間の選択',
                time: '時間',
                waitlistDeadlineWarning:
                    'ご指定いただいた日の{{time}}時まで待機いただけます。',
                waitlistRegistration: 'ウェイティングリストへの登録',
                youWontBeChargedYet: 'まだ請求されません',
            },
            info: {
                label: '情報',
            },
            recommendations: {
                label: 'レビュー',
            },
        },
        viewAllReviews: '{{count}}件のレビューを表示',
        waitlist: {
            explanation:
                'お客様のウェイティングリスト登録条件に合致した空席が出ますと、ご予約確定のご連絡をさせて頂きます。空席が出た場合には、「即時予約確定」となりますのでご注意願います。',
            footnote:
                'ウェイティングリストの登録は、お席のご案内をお約束するものではございません。',
            title: 'ウェイティングリストに登録',
        },
        website: 'ホームページ',
    },
    viewAll: 'すべてを見る',
    viewCourses: {
        DINNER: 'ディナーコースを表示する',
        LUNCH: 'ランチコースを表示する',
    },
    waitlist: 'ウェイティングリスト',
};
