/* eslint-disable react/no-danger */
import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Button from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import MutationStatus from 'components/Form/MutationStatus';
import SubmitButton from 'components/Form/SubmitButton';
import MobileReservationInformation from 'components/ReservationInformation/MobileReservationInformation';
import {
    Course,
    CreateReservationDocument,
    CreditCard,
    ReservationCreateInput,
    ReserveUserProfileFragment,
    ReserveVenueFragment,
} from 'gql/generated';
import {useAnalytics} from 'hooks/useAnalytics';
import {ErrorEnum} from 'hooks/useServerErrorHandling';
import {PageName} from 'pages/Analytics';
import {useLocale} from 'state/locale';
import {useToastNotification} from 'state/toastNotification';
import {ReserveSummaryFormData} from 'types/reserve';
import {withValues} from 'utils/object';
import {checkIfWaitlist} from 'utils/params';
import SummaryContactInformation from './SummaryContactInformation';
import SummaryDetails from './SummaryDetails';
import SummaryPayment from './SummaryPayment';
import SummaryPolicies from './SummaryPolicies';

export type ReserveSummaryProps = {
    course: Course;
    creditCards: CreditCard[];
    input: ReservationCreateInput;
    onBack: () => void;
    onSubmit: (id: string) => void;
    user: ReserveUserProfileFragment;
    venue: ReserveVenueFragment;
};

const ReserveSummary: FC<ReserveSummaryProps> = ({
    course,
    creditCards,
    input,
    onBack,
    onSubmit,
    user,
    venue,
}) => {
    const {t} = useTranslation();
    const {toastWrapper} = useToastNotification();
    const locale = useLocale();

    useAnalytics(`${PageName.Reserve}: Summary`);

    const [searchParams] = useSearchParams();
    const isWaitlist = checkIfWaitlist(searchParams);
    const isRealtime = venue.realTimeBooking;

    const submitButtonTitle = isWaitlist
        ? t('reserve.summary.waitlist.submit')
        : isRealtime
        ? t('reserve.summary.realTimeBooking.submit')
        : t('reserve.summary.reservationRequest.submit');

    const methods = useForm<ReserveSummaryFormData>({
        defaultValues: {agree: false},
    });

    const navigate = useNavigate();

    const {handleSubmit} = methods;

    const [reserve] = useMutation(CreateReservationDocument);

    const onSubmitForm = async () => {
        // strip out empty string fields
        const onBehalfOfGuest = input.onBehalfOfGuest
            ? withValues(input.onBehalfOfGuest)
            : undefined;
        const userGuest = input.userGuest
            ? withValues(input.userGuest)
            : undefined;

        await toastWrapper({
            onFulfilled: ({data}) =>
                onSubmit(data.reservationCreate.reservation.id),
            onRejected: (errorEnum) => {
                const redirectErrors = [
                    ErrorEnum.ReservationUnavailableError,
                    ErrorEnum.ReservationIneligibleBenefitsCardError,
                    ErrorEnum.ReservationIneligibleBenefitsUserError,
                ];

                // Redirect the user if the seat is no longer available
                if (errorEnum && redirectErrors.includes(errorEnum)) {
                    const venueURL = `${
                        locale === 'en' ? '/en' : ''
                    }/restaurants/${searchParams.get('venueId')}/`;
                    const searchParameters = `?date=${searchParams.get(
                        'date'
                    )}&partySize=${searchParams.get('partySize')}`;

                    navigate(
                        {
                            pathname: venueURL,
                            search: searchParameters,
                        },
                        {replace: true}
                    );
                }
            },
            promise: reserve({
                variables: {
                    input: withValues({
                        ...input,
                        onBehalfOfGuest,
                        userGuest,
                    }),
                },
            }),
        });
    };

    return (
        <FormProvider {...methods}>
            <form
                className="md:border-outline grid grid-rows-mobile-2-reverse overflow-hidden md:mt-4 md:block md:overflow-auto md:rounded-md md:border md:p-5"
                onSubmit={handleSubmit(onSubmitForm)}
            >
                <BreakpointWrapper
                    breakpoint="md"
                    className="overflow-y-auto pt-4"
                >
                    <MobileReservationInformation />
                    <div className="px-4 md:px-0">
                        <SummaryContactInformation input={input} user={user} />
                        <SummaryDetails input={input} venue={venue} />
                        <SummaryPayment
                            creditCards={creditCards}
                            input={input}
                        />
                        <SummaryPolicies
                            course={course}
                            isWaitlist={isWaitlist}
                            venue={venue}
                        />
                    </div>
                    <div className="border-outline md:bg-step mt-4 border-t px-4 pt-5 md:border-none md:pb-2.5 md:pt-4">
                        <Checkbox
                            label={
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            'reserve.summary.agreeToTerms'
                                        ),
                                    }}
                                />
                            }
                            name="agree"
                            required={t('form.required')}
                            size="sm"
                        />
                    </div>
                    <MutationStatus className="mt-4" />
                </BreakpointWrapper>
                <div className="shadow-top md:bg-body flex p-4 md:mt-4 md:justify-between md:px-0 md:pb-0 md:shadow-none">
                    <Button
                        className="mt-0 hidden w-auto md:block"
                        kind="secondary"
                        onClick={onBack}
                    >
                        {t('back')}
                    </Button>
                    <SubmitButton className="w-full md:w-auto">
                        {submitButtonTitle}
                    </SubmitButton>
                </div>
            </form>
        </FormProvider>
    );
};

export default ReserveSummary;
