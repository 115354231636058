import {FC, FormEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import clsx from 'clsx';
import BaseSelect from 'components/Form/Base/BaseSelect';
import {SelectOption} from 'components/Form/Base/BaseSelect/types';
import Cookies from 'js-cookie';
import {useLocale} from 'state/locale';
import {Locale} from 'types';

export const updateLocaleURL = (
    pathname: string,
    locale: Locale,
    navigate: (href: string) => void
) => {
    let next = '';

    if (locale === 'en') {
        next = `/${locale}${pathname}`;
        // just in case
        next = next.split('/en/en/').join('/en/');
    } else if (locale === 'ja') {
        if (pathname.includes('/en/')) {
            next = pathname.replace('/en/', '/');
        } else {
            next = pathname.replace('/en', '/');
        }
    }

    // This is a workaround to make sure that the Chinese locale is removed from the URL when switching language; https://jira.aexp.com/jira/browse/PDE-3540
    next = next.replace('/zh/', '/');

    if (next !== pathname) {
        // wait for pocket_locale cookie to be written
        navigate(next);
    }
};

const FULL: SelectOption[] = [
    {icon: ['fas', 'globe'], label: '日本語', value: 'ja'},
    {icon: ['fas', 'globe'], label: 'English', value: 'en'},
];

export type LocaleSelectorProps = {
    className?: string;
    classNameIcon?: string;
    classNameSelect?: string;
};

const LocaleSelector: FC<LocaleSelectorProps> = ({
    className,
    classNameIcon,
    classNameSelect,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();
    const {pathname} = useLocation();

    const onChange = async (event: FormEvent<HTMLSelectElement>) => {
        // Improvised fix until we fixed the subdomain problem on production
        const options: Cookies.CookieAttributes = {
            domain: 'pocket-concierge.jp',
        };

        if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === '127.0.0.1'
        ) {
            delete options.domain;
        }

        const {value} = event.currentTarget;
        Cookies.set('pocket_locale', value, options);
        updateLocaleURL(pathname, value as Locale, (href) => {
            // Safari 16 has a weird interaction with react-router whereas it does not reload the page; window.location helps.
            setTimeout(() => {
                window.location.href = href;
            });
        });
    };

    return (
        <BaseSelect
            key={locale}
            aria-label={t('selectLanguage')}
            className={className}
            classNameIcon={clsx('top-[0.2rem] left-[0.2rem]', classNameIcon)}
            classNameSelect={clsx(
                'border-transparent dark:border-transparent text-sm py-1',
                'focus:border-transparent dark:focus:border-transparent',
                classNameSelect
            )}
            data-dd-privacy="allow"
            name="language-selector"
            onChange={onChange}
            options={FULL}
            value={locale}
        />
    );
};

export default LocaleSelector;
