import {Route} from 'react-router-dom';
import PageLayout from 'components/Layout/PageLayout';
import ReservePage from 'pages/Reserve';

const ReservationRoutes = () => (
    <Route
        element={
            <PageLayout
                hideFooter={true}
                isAuthenticatedPage={true}
                isFullWidth={true}
                isSessionDisabled={true}
            />
        }
    >
        {/*The reserve page doesn't need the <Analytics /> wrapper because it handles the tracking by itself */}
        <Route element={<ReservePage />} path="reserve" />
    </Route>
);

export default ReservationRoutes;
