import {FC} from 'react';
import MarketingCard from 'components/Venue/MarketingCard';
import {useLocale} from 'state/locale';
import {MarketingContentGroup} from '../marketing-content';

type TitleProps = {
    name: string;
};

const Title: FC<TitleProps> = ({name}) => {
    return (
        <div className="sm:site-container bg-body sticky inset-x-0 top-[3.25rem] z-40 flex items-start justify-between px-4 py-1 sm:static">
            <div>
                <h3 className="text-xl">{name}</h3>
            </div>
        </div>
    );
};

const HomeMarketingGroup = ({
    marketingContent,
    title,
}: {
    marketingContent: MarketingContentGroup;
    title?: string;
}) => {
    const locale = useLocale();

    return (
        <div>
            {title && <Title name={title} />}
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {marketingContent[locale].map((content) => (
                    <MarketingCard
                        key={content.path}
                        className="aspect-4/3"
                        imageURL={content.imageUrl}
                        isExternal={content.isExternal}
                        path={content.path}
                    />
                ))}
            </div>
        </div>
    );
};

export default HomeMarketingGroup;
