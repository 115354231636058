import {Route} from 'react-router-dom';
import MenuSidebarLayout from 'components/Layout/MenuSideBarLayout';
import PageLayout from 'components/Layout/PageLayout';
import ScrollToTop from 'components/Layout/ScrollToTop';
import {Analytics, PageName} from 'pages/Analytics';
import {CompanyProfilePage} from 'pages/Static/CompanyProfile';
import {ContactFormPage} from 'pages/Static/ContactForm';
import {CompanyProfilePage as CompanyProfilePageEN} from 'pages/Static/en/CompanyProfile';
import {PrivacyPolicyPage as PrivacyPolicyPageEN} from 'pages/Static/en/PrivacyPolicy';
import {TermsOfUsePage as TermsOfUsePageEN} from 'pages/Static/en/TermsOfUse';
import {TransactionPage as TransactionPageEN} from 'pages/Static/en/Transaction';
import {PrivacyPolicyPage} from 'pages/Static/PrivacyPolicy';
import staticMenuSections from 'pages/Static/StaticMenu/static-menu-sections';
import {TermsOfUsePage} from 'pages/Static/TermsOfUse';
import {TransactionPage} from 'pages/Static/Transaction';

const StaticRoutes = (isEnglishPage = false) => (
    <Route element={<PageLayout />}>
        <Route path="*">
            <Route element={<ScrollToTop />}>
                <Route
                    element={
                        <MenuSidebarLayout sections={staticMenuSections} />
                    }
                >
                    <Route
                        element={
                            <Analytics pageName={PageName.CompanyProfile}>
                                {isEnglishPage ? (
                                    <CompanyProfilePageEN />
                                ) : (
                                    <CompanyProfilePage />
                                )}
                            </Analytics>
                        }
                        index={true}
                        path={staticMenuSections.companyProfile.path}
                    />
                    <Route
                        element={
                            <Analytics pageName={PageName.TermsOfUse}>
                                {isEnglishPage ? (
                                    <TermsOfUsePageEN />
                                ) : (
                                    <TermsOfUsePage />
                                )}
                            </Analytics>
                        }
                        path={staticMenuSections.termsOfUse.path}
                    />
                    <Route
                        element={
                            <Analytics pageName={PageName.PrivacyPolicy}>
                                {isEnglishPage ? (
                                    <PrivacyPolicyPageEN />
                                ) : (
                                    <PrivacyPolicyPage />
                                )}
                            </Analytics>
                        }
                        path={staticMenuSections.privacyPolicy.path}
                    />
                    <Route
                        element={
                            <Analytics pageName={PageName.Transaction}>
                                {isEnglishPage ? (
                                    <TransactionPageEN />
                                ) : (
                                    <TransactionPage />
                                )}
                            </Analytics>
                        }
                        path={staticMenuSections.transaction.path}
                    />
                    <Route
                        element={
                            <Analytics pageName={PageName.ContactForm}>
                                <ContactFormPage />
                            </Analytics>
                        }
                        path={staticMenuSections.contact.path}
                    />
                </Route>
            </Route>
        </Route>
    </Route>
);

export const StaticRoutesJA = () => StaticRoutes(false);

export const StaticRoutesEN = () => StaticRoutes(true);
