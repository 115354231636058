import {FC, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import ServiceError from 'components/Errors/ServiceError';
import FadeIn from 'components/FadeIn';
import VenueSearchLoader from 'components/Loaders/VenueSearch';
import NoResults from 'components/NoResults';
import Pagination from 'components/Pagination';
import Search from 'components/Search';
import MarketingCard from 'components/Venue/MarketingCard';
import VenueResultCard from 'components/Venue/VenueResultCard';
import {
    SearchVenuesDocument,
    SearchVenuesQuery,
    VenueSearchResultFragment,
} from 'gql/generated';
import useBreakpoint from 'hooks/useBreakpoint';
import useScrollToTop from 'hooks/useScrollToTop';
import {useLocale} from 'state/locale';
import {SearchValues} from 'types/search';
import {getS3ImageURL} from 'utils/environment';
import {handleError} from 'utils/functions';
import {md5} from 'utils/object';
import {
    getSearchVariables,
    getURLSearchParams,
    getVenueSearchFromValues,
    parseSearchParams,
} from 'utils/params';

// TODO: This whole thing needs to be refactored to get rid of all these parsers written for GatsbyJS.
const SearchPage: FC = () => {
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const locale = useLocale();

    const isDesktop = useBreakpoint('sm');

    const searchValues = useMemo(
        () => parseSearchParams(searchParams),
        [searchParams]
    );

    const key = md5(searchValues);
    useScrollToTop(key);

    const venueSearch = useMemo(
        () => getVenueSearchFromValues(searchValues),
        [searchValues]
    );

    const [searchVenues, {data, error, loading}] =
        useLazyQuery<SearchVenuesQuery>(SearchVenuesDocument);

    useEffect(() => {
        const variables = getSearchVariables(searchValues);
        searchVenues({variables}).catch(handleError);
    }, [searchValues, searchVenues]);

    const onSearch = (formData: SearchValues) => {
        // The second argument is needed to omit the page;
        // This way we can reset the page each time to the first page when the user makes a new search
        setSearchParams(getURLSearchParams(formData, true));
    };

    const results = (data?.venuesSearch?.collection ||
        []) as VenueSearchResultFragment[];

    const metadata = data?.venuesSearch?.metadata;

    return (
        <>
            <div className="w-full bg-grey-100 py-4 dark:bg-grey-800">
                <div className="site-container">
                    <Search
                        key={`search_${key}`}
                        className="mt-4 sm:mt-6 xl:mt-6"
                        onSearch={onSearch}
                        searchValues={searchValues}
                    />
                </div>
            </div>
            <div
                key={`results_${key}`}
                className="site-container mt-4 min-h-[28.3125rem] sm:min-h-[27.25rem]"
            >
                {locale === 'ja' && (
                    <div className="flex flex-row items-center justify-center gap-x-4 px-0 pb-4">
                        <MarketingCard
                            assetName="Amex Curation"
                            imageClassName="sm:max-h-[120px] object-scale-down"
                            imageURL={getS3ImageURL(
                                'plat_curation_wide_banner'
                            )}
                            isExternal={true}
                            path="/lp/amex/benefit/curation/index.html?extlink=va-jp-ICS-RestaurantsPage_panel_Amex_Curation"
                        />
                        {isDesktop && (
                            <MarketingCard
                                assetName="Kiwami 50"
                                imageClassName="sm:max-h-[120px] object-scale-down"
                                imageURL={getS3ImageURL('search_page_2')}
                                isExternal={true}
                                path="https://www.americanexpress.com/jp/benefits/events-offers/premium-dining/index.html?extlink=va-jp-ICS-KIWAMI_Pocket_RestaurantsPage"
                            />
                        )}
                    </div>
                )}
                {loading ? (
                    <VenueSearchLoader />
                ) : error ? (
                    <ServiceError error={error} />
                ) : results.length > 0 ? (
                    <FadeIn delay="delay-200">
                        <div className="grid grid-cols-1 place-content-center place-items-stretch gap-4 sm:grid-cols-2 lg:grid-cols-3">
                            {results.map((venue) => (
                                <VenueResultCard
                                    key={venue?.id}
                                    search={venueSearch}
                                    venue={venue}
                                />
                            ))}
                        </div>
                        <div className="mt-8 flex min-h-[3.875rem] justify-center">
                            {metadata && (
                                <Pagination
                                    className="w-full max-w-md lg:max-w-2xl xl:max-w-3xl"
                                    metadata={metadata}
                                    name="restaurant"
                                />
                            )}
                        </div>
                    </FadeIn>
                ) : (
                    <NoResults
                        icon="restaurants"
                        label={t('search.noRestaurantsFound')}
                    />
                )}
                {locale === 'ja' && !isDesktop && (
                    <MarketingCard
                        assetName="Kiwami 50"
                        className="mt-8 h-fit"
                        imageURL={getS3ImageURL('search_page_2')}
                        isExternal={true}
                        path="https://www.americanexpress.com/jp/benefits/events-offers/premium-dining/index.html?extlink=va-jp-ICS-KIWAMI_Pocket_RestaurantsPage"
                    />
                )}
            </div>
        </>
    );
};

export default SearchPage;
