import {FC, useCallback, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import StripeAddCard from 'components/Stripe/StripeAddCard';
import {useStripeForm} from 'hooks/useStripeForm';
import {CCFormData} from 'types';

const UserPaymentStripeForm: FC = () => {
    const {t} = useTranslation();

    const methods = useForm<CCFormData>();
    const {clearErrors, handleSubmit, setError, setValue, watch} = methods;
    const creditCardName = watch('creditCardName');

    const [stripeFormKey, setStripeFormKey] = useState(1);

    // after credit card added, reset the Stripe fields
    const onSuccess = useCallback(() => {
        setStripeFormKey((p) => p + 1);
        setValue('creditCardName', '');
    }, [setValue]);

    const onError = useCallback(
        (message: string) => {
            if (message) {
                setError('serverError', {
                    message:
                        message === '500' ? t('error.500.message') : message,
                    type: 'serverError',
                });
            } else {
                clearErrors('serverError');
            }
        },
        [clearErrors, setError, t]
    );

    const {onAddCardSubmit} = useStripeForm();

    return (
        <FormProvider {...methods}>
            <form
                key={stripeFormKey}
                onSubmit={handleSubmit(() =>
                    onAddCardSubmit({
                        creditCardName,
                        onError,
                        onSuccess,
                    })
                )}
            >
                <StripeAddCard onError={onError} />
            </form>
        </FormProvider>
    );
};

export default UserPaymentStripeForm;
