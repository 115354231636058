import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {
    CardNumberElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import {
    AddCardDocument,
    ConfirmSetupIntentDocument,
    CreditCardSearchDocument,
    RequestSetupIntentDocument,
    SetupIntentStatus,
} from 'gql/generated';
import {ToastType, useToastNotification} from 'state/toastNotification';
import {logError} from './useServerErrorHandling';

type AddCardType = {
    creditCardName: string;
    onError: (message: string) => void;
    onSuccess?: (paymentMethodId: string) => void;
};

export const useStripeForm = () => {
    const stripe = useStripe();
    const {t} = useTranslation();
    const elements = useElements();
    const {showToast, toastWrapper} = useToastNotification();
    const [loading, setLoading] = useState(false);

    const [addCard] = useMutation(AddCardDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [CreditCardSearchDocument],
    });

    const [requestSetupIntent] = useMutation(RequestSetupIntentDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [CreditCardSearchDocument],
    });

    const [confirmSetupIntent] = useMutation(ConfirmSetupIntentDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [CreditCardSearchDocument],
    });

    const requestAndConfirmSetupIntent = useCallback(
        async ({
            callback,
            creditCardId,
        }: {
            callback?: () => void;
            creditCardId: string;
        }) => {
            setLoading(true);

            const {data} = await requestSetupIntent({
                variables: {id: creditCardId},
            }).then(
                (result) => result,
                () => {
                    showToast({
                        message: t('error.threeDSecure'),
                        type: ToastType.Error,
                    });
                    logError(new Error('requestSetupIntent ran into an error'));
                    setLoading(false);

                    return {
                        data: {},
                    };
                }
            );

            if (data?.requestSetupIntent?.creditCard?.confirmationNeeded) {
                await stripe
                    ?.confirmSetup({
                        clientSecret: data.requestSetupIntent.clientSecret,
                        confirmParams: {
                            return_url: window.location.href,
                        },
                        redirect: 'if_required',
                    })
                    .then(({error}) => {
                        if (error) {
                            setLoading(false);
                            showToast({
                                message: t('error.threeDSecure'),
                                type: ToastType.Error,
                            });
                            logError(new Error('3DS Confirmation Error'));
                        } else {
                            confirmSetupIntent({
                                variables: {id: creditCardId},
                            }).then(
                                ({
                                    data: {
                                        confirmSetupIntent:
                                            gqlConfirmSetupIntent,
                                    },
                                    errors,
                                }) => {
                                    setLoading(false);

                                    if (
                                        errors ||
                                        gqlConfirmSetupIntent.stripeSetupIntentStatus !==
                                            SetupIntentStatus.Succeeded
                                    ) {
                                        showToast({
                                            message: t('error.threeDSecure'),
                                            type: ToastType.Error,
                                        });
                                        logError(
                                            new Error(
                                                'confirmSetupIntent did not return succeeded'
                                            )
                                        );
                                    } else {
                                        callback?.();
                                    }
                                }
                            );
                        }
                    });
            } else {
                setLoading(false);
            }
        },
        [confirmSetupIntent, requestSetupIntent, showToast, stripe, t]
    );

    const onAddCardSubmit = useCallback(
        async ({creditCardName, onError, onSuccess}: AddCardType) => {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            const card = elements.getElement(CardNumberElement);

            if (!card) return;

            setLoading(true);

            const {error: paymentMethodError, paymentMethod} =
                await stripe.createPaymentMethod({
                    billing_details: {
                        name: creditCardName,
                    },
                    card,
                    type: 'card',
                });

            if (paymentMethodError) {
                onError(paymentMethodError.message || paymentMethodError.type);
                setLoading(false);
            } else if (paymentMethod) {
                await toastWrapper({
                    onFulfilled: async ({data}) => {
                        const {clientSecret, creditCard} = data.addCard;
                        const {confirmationNeeded, id} = creditCard;

                        onSuccess?.(id);

                        if (confirmationNeeded) {
                            await stripe
                                ?.confirmSetup({
                                    clientSecret,
                                    confirmParams: {
                                        return_url: window.location.href,
                                    },
                                    redirect: 'if_required',
                                })
                                .then(({error}) => {
                                    if (error) {
                                        setLoading(false);
                                        showToast({
                                            message: t('error.threeDSecure'),
                                            type: ToastType.Error,
                                        });
                                        logError(
                                            new Error('3DS Confirmation Error')
                                        );
                                    } else {
                                        confirmSetupIntent({
                                            variables: {id},
                                        }).then(() => {
                                            setLoading(false);
                                        });
                                    }
                                });
                        }
                    },
                    promise: addCard({
                        variables: {stripeToken: paymentMethod.id},
                    }),
                });
            } else {
                onError('500');
            }
        },
        [
            addCard,
            confirmSetupIntent,
            elements,
            showToast,
            stripe,
            t,
            toastWrapper,
        ]
    );

    return {
        loading,
        onAddCardSubmit,
        requestAndConfirmSetupIntent,
    };
};
