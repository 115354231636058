/* eslint-disable react/no-danger */
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {ThreeDSecureIcon} from 'assets/icons/ThreeDSecure';
import {TrashCanIcon} from 'assets/icons/TrashCan';
import CreditCardImage from 'components/CreditCardImage';
import {
    CreditCard,
    CreditCardBrand,
    CreditCardSearchDocument,
    PaymentMethodRemoveDocument,
} from 'gql/generated';
import {useStripeForm} from 'hooks/useStripeForm';
import {useToastNotification} from 'state/toastNotification';
import {isExpired, toMY} from 'utils/date';
import Button from '../Button';

type PaymentMethodProps = {
    card: CreditCard;
    onRemove?: (id: string) => void;
};

export const BRANDS: Record<CreditCardBrand, string> = {
    [CreditCardBrand.Amex]: 'Amex',
    [CreditCardBrand.DinersClub]: 'Diners Club',
    [CreditCardBrand.Discover]: 'Discover',
    [CreditCardBrand.Jcb]: 'JCB',
    [CreditCardBrand.Mastercard]: 'Mastercard',
    [CreditCardBrand.Unionpay]: 'Union Pay',
    [CreditCardBrand.Visa]: 'Visa',
};

const PaymentMethod: FC<PaymentMethodProps> = ({card, onRemove}) => {
    const {t} = useTranslation();
    const {toastWrapper} = useToastNotification();
    const {loading: reAuthLoading, requestAndConfirmSetupIntent} =
        useStripeForm();
    const {
        cardBrand,
        cardExpiryMonth,
        cardExpiryYear,
        cardLast4,
        confirmationNeeded,
        id,
    } = card;

    const isCardExpired = isExpired(`${cardExpiryMonth}/${cardExpiryYear}`);
    const expires = toMY(new Date(cardExpiryYear, cardExpiryMonth - 1));

    // This is rendered as html because react-i18next uses the ascii symbol for the forward slash
    const expiration = {
        __html: isCardExpired
            ? t('user.paymentMethods.expired', {
                  expires,
              })
            : t('user.paymentMethods.expires', {
                  expires,
              }),
    };

    const [removeCard, {loading}] = useMutation(PaymentMethodRemoveDocument, {
        awaitRefetchQueries: true,
        refetchQueries: [CreditCardSearchDocument],
    });

    const onRemoveCard = async () => {
        await toastWrapper({
            onFulfilled: () => onRemove?.(id),
            promise: removeCard({variables: {id}}),
        });
    };

    const onAuthCard = async () => {
        await requestAndConfirmSetupIntent({
            creditCardId: id,
        });
    };

    return (
        <div className="mx-2 grid grid-cols-7 place-items-center justify-center py-6">
            <CreditCardImage
                className={`col-span-1 place-self-start justify-self-start  ${
                    isCardExpired ? 'opacity-80 grayscale dark:opacity-100' : ''
                }`}
                size="lg"
                type={cardBrand}
            />
            <div className="col-span-4 grid w-full grid-cols-2 grid-rows-2 px-6 sm:grid-cols-4 sm:grid-rows-1">
                <span
                    className={`col-span-2 justify-self-start font-normal sm:justify-self-center ${
                        isCardExpired ? 'opacity-50' : ''
                    }`}
                >
                    {t('user.paymentMethods.cardEndingIn', {
                        cardLast4,
                    })}
                </span>
                <span
                    dangerouslySetInnerHTML={expiration}
                    className={`col-span-2 justify-self-start sm:justify-self-center ${
                        isCardExpired ? 'opacity-50' : ''
                    }`}
                />
            </div>
            <div className="col-span-2 flex max-h-4 items-center self-start justify-self-end sm:self-center">
                {onRemove && (
                    <Button
                        aria-label={t('user.paymentMethods.removeCard')}
                        className="flex flex-row border-none bg-transparent"
                        kind="secondary"
                        loading={
                            loading
                                ? t('user.paymentMethods.removeCard')
                                : undefined
                        }
                        onClick={onRemoveCard}
                        size="sm"
                    >
                        <TrashCanIcon className="mr-1 place-self-center" />
                        {t('user.paymentMethods.removeCard')}
                    </Button>
                )}
            </div>
            {!isCardExpired && confirmationNeeded && (
                <div className="col-span-7 mt-4 flex w-full flex-col justify-between rounded bg-gold-100 p-3 dark:text-grey-900 sm:flex-row sm:py-2">
                    <div className="flex flex-row">
                        <ThreeDSecureIcon className="h-6 w-6 min-w-[24px] sm:place-self-center" />
                        <div className="ml-2 flex flex-col text-sm">
                            <span className="text-base sm:text-sm">
                                {t('user.paymentMethods.threeDTitle')}
                            </span>
                            <span>
                                {t('user.paymentMethods.threeDSubTitle')}
                            </span>
                        </div>
                    </div>
                    <Button
                        aria-label={t('user.paymentMethods.threeDButtonTitle')}
                        className="mt-2 max-w-min shrink place-self-end bg-gold-200 px-4 py-1 font-normal text-white dark:text-grey-900 sm:my-auto"
                        kind="custom"
                        loading={
                            reAuthLoading
                                ? t('user.paymentMethods.threeDButtonTitle')
                                : undefined
                        }
                        onClick={onAuthCard}
                        size="sm"
                        spinnerColor="gold"
                    >
                        {t('user.paymentMethods.threeDButtonTitle')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default PaymentMethod;
