/* eslint-disable react/no-danger */
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import BookmarkButton from 'components/BookmarkButton';
import Server404 from 'components/Errors/Server404';
import Server500 from 'components/Errors/Server500';
import ImageCarousel from 'components/ImageCarousel';
import Spinner from 'components/Loaders/Spinner';
import MobileReservationWidget from 'components/ReservationWidget/ModalWrapper';
import ReservationWidget from 'components/ReservationWidget/ReservationWidget';
import VenueArea from 'components/Venue/VenueArea';
import VenueCuisines from 'components/Venue/VenueCuisines';
import VenuePriceRanges from 'components/Venue/VenuePriceRanges';
import VenueTabs from 'components/Venue/VenueTabs';
import {
    VenuePageDocument,
    VenuePageQuery,
    VenueWithCoursesFragment,
} from 'gql/generated';
import {useAnalytics} from 'hooks/useAnalytics';
import useBreakpoint from 'hooks/useBreakpoint';
import useScrollToTop from 'hooks/useScrollToTop';
import {camelCase} from 'lodash';
import {PageName} from 'pages/Analytics';
import {sanitizeTextBlock} from 'utils/string';

type Props = {
    isSecretSeat?: boolean;
};

const VenuePage: FC<Props> = ({isSecretSeat = false}) => {
    useScrollToTop();

    const isDesktop = useBreakpoint('md');

    const {t} = useTranslation();

    const {id} = useParams();

    const {data, error, loading} = useQuery<VenuePageQuery>(VenuePageDocument, {
        variables: {id},
    });

    const venue = data?.venue || ({} as VenueWithCoursesFragment);

    const {
        blurb,
        cuisines,
        id: venueId,
        imageUrls,
        longDescription,
        name,
        priceRanges,
        realTimeBooking,
        services,
    } = venue;

    const [isCollapsed, setIsCollapsed] = useState(true);

    const onToggleText = () => {
        if (isCollapsed) {
            setIsCollapsed(false);
        } else {
            setIsCollapsed(true);

            if (!isDesktop) {
                window.scrollTo({
                    left: 0,
                    top: 0,
                });
            }
        }
    };

    useAnalytics(
        venueId
            ? `${
                  isSecretSeat ? PageName.SecretSeat : PageName.Restaurant
              }: ${venueId}`
            : undefined
    );

    if (loading) {
        return (
            <Spinner
                className="mx-auto min-h-[42rem] w-full"
                size="5x"
                title={t('form.pleaseWait')}
            />
        );
    }

    if (error) {
        if (error.graphQLErrors[0]?.extensions.code === 'NOT_FOUND_ERROR') {
            return <Server404 />;
        }

        return <Server500 error={error} />;
    }

    return (
        <div className="split-view md:mt-4">
            <div className="split-view-wide">
                <ImageCarousel images={imageUrls} name={name} />
                <div className="relative mx-auto w-full">
                    <div className="relative rounded-b-md border border-grey-100 bg-white px-5 py-4 shadow-md dark:border-grey-700 dark:bg-grey-900">
                        <div className="flex flex-row justify-between">
                            <div className="text-xl md:text-2xl">{name}</div>
                            <BookmarkButton
                                className="ml-2 h-8 w-28 min-w-fit"
                                showLabel={true}
                                venue={venue}
                            />
                        </div>
                        <VenueArea
                            className="mt-2.5 sm:text-sm"
                            isClickable={true}
                            venue={venue}
                        />
                        <VenueCuisines
                            className="mt-1 sm:text-sm"
                            cuisines={cuisines}
                            isClickable={true}
                        />
                        <VenuePriceRanges
                            className="py-4"
                            priceRanges={priceRanges}
                        />
                        <div className="flex flex-wrap gap-2">
                            {realTimeBooking && (
                                <div className="whitespace-nowrap rounded-md bg-grey-100 px-2.5 py-1.5 text-sm leading-none dark:bg-grey-800">
                                    {t('search.realTimeBooking')}
                                </div>
                            )}
                            {services.map((service) => (
                                <div
                                    key={service}
                                    className="whitespace-nowrap rounded-md bg-grey-100 px-2.5 py-1.5 text-sm leading-none dark:bg-grey-800"
                                >
                                    {t(`venue.services.${camelCase(service)}`)}
                                </div>
                            ))}
                        </div>
                    </div>
                    {blurb && (
                        <div className="relative mt-4 px-1 font-semibold dark:text-grey-100 sm:mt-8 sm:px-6">
                            {blurb}
                        </div>
                    )}
                    <div
                        className={clsx(
                            'relative mt-3 px-1 sm:mt-4 sm:px-6',
                            isCollapsed
                                ? 'h-[8.75rem] overflow-hidden'
                                : undefined
                        )}
                    >
                        <article
                            dangerouslySetInnerHTML={{
                                __html: sanitizeTextBlock(longDescription),
                            }}
                            className="prose prose-sm max-w-none dark:prose-dark md:prose md:max-w-none"
                        />
                    </div>
                    {isCollapsed && (
                        <div className="read-more-gradient relative -mt-36 h-36" />
                    )}
                    <div
                        className={clsx(
                            'text-center',
                            isCollapsed ? 'mt-1' : 'mt-5'
                        )}
                    >
                        <button
                            className="text-golden text-sm"
                            onClick={onToggleText}
                            type="button"
                        >
                            <span>
                                {t(isCollapsed ? 'readMore' : 'readLess')}
                            </span>
                            <FontAwesomeIcon
                                className="ml-2 text-gold-200"
                                icon={[
                                    'fas',
                                    isCollapsed ? 'chevron-down' : 'chevron-up',
                                ]}
                            />
                        </button>
                    </div>
                    <VenueTabs venue={venue} />
                </div>
            </div>
            {isDesktop ? (
                <ReservationWidget
                    className="split-view-narrow"
                    isSecretSeat={isSecretSeat}
                    venue={venue}
                />
            ) : (
                <MobileReservationWidget
                    isSecretSeat={isSecretSeat}
                    venue={venue}
                />
            )}
        </div>
    );
};

export default VenuePage;
