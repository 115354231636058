import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import LocaleSelector from 'components/LocaleSelector';
import {useLogout} from 'hooks/useLogout';
import {useLocale} from 'state/locale';
import {useUser} from 'state/user';
import DesktopUserMenuItem from './DesktopUserMenuItem';
import styles from './styles.module.css';

const DesktopUserMenu: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();

    const user = useUser();
    const fullName = user ? t('user.fullName', user) : undefined;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const logout = useLogout(() => setIsOpen(false));

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onClickOutside = (event: MouseEvent) => {
            if (!ref.current?.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', onClickOutside);

        return () => document.removeEventListener('mousedown', onClickOutside);
    }, [setIsOpen]);

    const onClickLogout = async () => {
        await logout();
    };

    return (
        <div className="flex flex-row justify-center gap-x-2">
            <LocaleSelector
                classNameSelect={`hover:cursor-pointer ${
                    locale === 'en' ? 'pr-8' : 'pr-7'
                }`}
            />
            <div
                ref={ref}
                className="relative flex"
                data-dd-action-name="UserMenu"
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={() => setIsOpen(true)}
                role="button"
                tabIndex={0}
            >
                <div className="flex items-center justify-center space-x-2">
                    <div data-dd-privacy="mask">{fullName}</div>
                    <div className="flex items-center justify-center pt-1">
                        <div className={styles.button}>
                            <div />
                            <div />
                            <div />
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <ul
                        className={clsx(
                            'bg-body absolute right-0 mt-8 flex flex-col rounded-md text-right',
                            styles.shadow
                        )}
                    >
                        <DesktopUserMenuItem path="/account/reservations/">
                            {t('user.reservations.title')}
                        </DesktopUserMenuItem>
                        <DesktopUserMenuItem path="/account/bookmarks/">
                            {t('bookmarks.title')}
                        </DesktopUserMenuItem>
                        <DesktopUserMenuItem path="/account/profile/">
                            {t('user.profile')}
                        </DesktopUserMenuItem>
                        <DesktopUserMenuItem
                            path={
                                locale === 'ja'
                                    ? process.env.CUSTOMER_HELP_FAQ_JA
                                    : process.env.CUSTOMER_HELP_FAQ_EN
                            }
                        >
                            {t('staticPages.faq')}
                        </DesktopUserMenuItem>
                        <DesktopUserMenuItem onClick={onClickLogout}>
                            {t('authentication.logout')}
                        </DesktopUserMenuItem>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default DesktopUserMenu;
