import {ChangeEvent, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useLocale} from 'state/locale';
import {getDateString, toISO8601Date} from 'utils/date';

type Props = {
    availableDates: string[];
    date: Date;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
};

const DateSelection: FC<Props> = ({availableDates, date, onChange}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const options = availableDates.map((dateString) => ({
        icon: ['far', 'calendar'],
        label: getDateString(new Date(dateString), locale, true),
        value: dateString,
    }));

    return (
        <div className="relative">
            <select
                aria-label={t('date-selector')}
                className="w-full select-none pl-9"
                data-dd-action-name="ReservationWidgetCalendar"
                onChange={onChange}
                value={toISO8601Date(date)}
            >
                {options.map((item) => (
                    <option key={item.value} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
            <div className="pointer-events-none absolute left-[0.8rem] top-[0.575rem]">
                <FontAwesomeIcon icon={['far', 'calendar']} />
            </div>
        </div>
    );
};

export default DateSelection;
