import {FC} from 'react';
import {useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ButtonAnchor from 'components/ButtonAnchor';
import RadioButtons from 'components/Form/RadioButtons';
import StripeAddCard from 'components/Stripe/StripeAddCard';
import {useLocale} from 'state/locale';
import {Option} from 'types';
import {ReservePaymentFormData} from 'types/reserve';
import Ryoshusho from './Ryoshusho';

type CreditCardPaymentProps = {
    creditCardOptions: Option[];
    onError: (message: string) => void;
};

const CreditCardPayment: FC<CreditCardPaymentProps> = ({
    creditCardOptions,
    onError,
}) => {
    const locale = useLocale();
    const {t} = useTranslation();

    const {register, setValue, watch} =
        useFormContext<ReservePaymentFormData>();
    const creditCardId = watch('creditCardId');

    const onClickAddNewCard = () => {
        setValue('creditCardId', 'new');
    };

    const isNewPaymentMethod = creditCardId === 'new';
    const addPaymentRequired = creditCardOptions.length === 0;
    const showRyoshusho =
        !isNewPaymentMethod && creditCardId !== '' && locale === 'ja';

    return (
        <fieldset>
            <div className="overflow-y-auto pt-4 md:pt-0">
                <h3 className="flex flex-col px-4 pb-4 md:px-0">
                    <span>{t('reserve.payment.onlinePayment')}</span>
                    <span className="text-sm">
                        {t('reserve.payment.selectPaymentMethod')}
                    </span>
                </h3>
                {creditCardOptions.length > 0 && (
                    <RadioButtons
                        className="bg-step mx-4 flex flex-col justify-items-center rounded-md p-4 md:mx-0 md:px-8 md:py-4"
                        classNameGroup="divide-y divide-grey-200 dark:divide-grey-700"
                        classnameLabel="mt-7"
                        isFullWidth={true}
                        name="creditCardId"
                        options={creditCardOptions}
                    />
                )}
                <div className="bg-step mx-4 mt-4 rounded-md p-4 md:mx-0 md:px-8 md:py-4">
                    {isNewPaymentMethod ? (
                        <>
                            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                {addPaymentRequired ? (
                                    <div className="select-none font-semibold">
                                        {t('user.paymentMethods.addNewCard')}
                                    </div>
                                ) : (
                                    <label
                                        className="flex cursor-pointer select-none items-center"
                                        htmlFor="addNewStripeCreditCard"
                                    >
                                        <input
                                            className="focus-ring h-5 w-5 md:h-6 md:w-6"
                                            id="addNewStripeCreditCard"
                                            {...register('creditCardId')}
                                            type="radio"
                                            value="new"
                                        />
                                        <div className="ml-4 select-none text-sm font-semibold">
                                            {t(
                                                'user.paymentMethods.addNewCard'
                                            )}
                                        </div>
                                    </label>
                                )}
                            </div>
                            <StripeAddCard onError={onError} />
                        </>
                    ) : (
                        <ButtonAnchor
                            className="text-sm"
                            onClick={onClickAddNewCard}
                        >
                            <span>{t('user.paymentMethods.addNewCard')}</span>
                            <FontAwesomeIcon
                                className="ml-2 text-gold-200"
                                icon={['fas', 'chevron-right']}
                            />
                        </ButtonAnchor>
                    )}
                </div>
            </div>
            {showRyoshusho && <Ryoshusho />}
        </fieldset>
    );
};

export default CreditCardPayment;
