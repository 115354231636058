import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Button from 'components/Button';
import MobilePanelHeader from 'components/MobilePanelHeader';
import {
    addMonths,
    getDaysInMonth,
    isAfter,
    isBefore,
    isSameMonth,
    lastDayOfMonth,
    setDate,
    subMonths,
} from 'date-fns';
import {useLocale} from 'state/locale';
import {AvailabilityDates} from 'types/reservation';
import {toFullDate} from 'utils/date';
import {getFirstEnabledDate, getLastEnabledDate, getSafeDate} from '../utils';
import DateSelectorCalendar from './DateSelectorCalendar';
import DateSelectorNav from './DateSelectorNav';

export type DateSelectorPanelProps = {
    availabilityDates?: AvailabilityDates;
    date?: Date;
    id: string;
    isClearable: boolean;
    isCloseOnSelect: boolean;
    isDesktop: boolean;
    maxDate?: Date;
    minDate?: Date;
    onClearDate: () => void;
    onDateClick: (value: Date) => void;
    onToggle: (() => void) | undefined;
};

const DateSelectorPanel: FC<DateSelectorPanelProps> = ({
    availabilityDates,
    date,
    id,
    isClearable,
    isCloseOnSelect,
    isDesktop,
    maxDate,
    minDate,
    onClearDate,
    onDateClick,
    onToggle,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const [visibleDate, setVisibleDate] = useState(
        date || minDate || getSafeDate(new Date())
    );

    const [currentFocus, setCurrentFocus] = useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        if (!date) {
            setTimeout(() => {
                // on mount set initial focus to first enabled date
                const initialFocus = getFirstEnabledDate(id);

                if (initialFocus) {
                    setCurrentFocus(initialFocus);
                }
            }, 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentFocus) {
            const element: HTMLButtonElement | null = document.querySelector(
                `#${currentFocus}`
            );
            if (element && !element.disabled) element.focus();
        }
    }, [currentFocus]);

    const isNextDisabled = useMemo(
        () => !!maxDate && isSameMonth(maxDate, visibleDate),
        [maxDate, visibleDate]
    );

    const isPreviousDisabled = useMemo(
        () => !!minDate && isSameMonth(minDate, visibleDate),
        [minDate, visibleDate]
    );

    const onNext = useCallback(() => {
        if (!isNextDisabled) {
            const nextMonth = addMonths(visibleDate, 1);
            const day = Math.min(
                getDaysInMonth(nextMonth),
                visibleDate.getDate()
            );

            if (!maxDate || isBefore(nextMonth, maxDate)) {
                setVisibleDate(setDate(nextMonth, day));
            } else {
                setVisibleDate(maxDate);
            }
        }
    }, [isNextDisabled, maxDate, visibleDate]);

    const onPrevious = useCallback(() => {
        if (!isPreviousDisabled) {
            const prevMonth = lastDayOfMonth(
                subMonths(
                    new Date(visibleDate.getFullYear(), visibleDate.getMonth()),
                    1
                )
            );
            const day = Math.min(
                getDaysInMonth(prevMonth),
                visibleDate.getDate()
            );

            if (!minDate || isAfter(prevMonth, minDate)) {
                setVisibleDate(setDate(prevMonth, day));
            } else {
                setVisibleDate(minDate);
            }
        }
    }, [isPreviousDisabled, minDate, visibleDate]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key.includes('Arrow')) {
                event.preventDefault();
                let nextFocus;
                const firstEnabledDate = getFirstEnabledDate(id);
                const lastEnabledDate = getLastEnabledDate(id);
                const firstEnabledNumber = firstEnabledDate
                    ? Number(firstEnabledDate.split('-').pop())
                    : undefined;
                const lastEnabledNumber = lastEnabledDate
                    ? Number(lastEnabledDate.split('-').pop())
                    : undefined;
                const currentNumber = currentFocus?.includes(
                    'date-selector-date'
                )
                    ? Number(currentFocus.split('-').pop())
                    : undefined;

                if (event.key === 'ArrowUp') {
                    if (currentNumber !== undefined) {
                        if (currentNumber < 7) {
                            // select when at top of enabled dates
                            if (isNextDisabled && isPreviousDisabled) {
                                nextFocus = firstEnabledDate;
                            } else if (isNextDisabled) {
                                nextFocus = `${id}-date-selector-nav-previous`;
                            } else {
                                nextFocus = `${id}-date-selector-nav-next`;
                            }
                        } else if (firstEnabledNumber !== undefined) {
                            // select the first enabled date one week before
                            if (currentNumber - 7 < firstEnabledNumber) {
                                nextFocus = `${id}-date-selector-nav-next`;
                            } else {
                                nextFocus = `${id}-date-selector-date-${
                                    currentNumber - 7
                                }`;
                            }
                        }
                    }
                } else if (event.key === 'ArrowDown') {
                    if (!currentFocus) {
                        // this should only happen if there is no selected date
                        if (isNextDisabled && isPreviousDisabled) {
                            nextFocus = firstEnabledDate;
                        } else if (isPreviousDisabled) {
                            nextFocus = `${id}-date-selector-nav-next`;
                        } else {
                            nextFocus = `${id}-date-selector-nav-previous`;
                        }
                    } else if (currentFocus.includes('date-selector-nav')) {
                        nextFocus = firstEnabledDate;
                    } else if (currentNumber !== undefined) {
                        if (
                            lastEnabledNumber !== undefined &&
                            currentNumber + 7 > lastEnabledNumber
                        ) {
                            nextFocus = lastEnabledDate;
                        } else {
                            // select the last enabled date one week after
                            nextFocus = `${id}-date-selector-date-${
                                currentNumber + 7
                            }`;
                        }
                    }
                } else if (event.key === 'ArrowRight' && currentFocus) {
                    if (currentFocus.includes('date-selector-nav-previous')) {
                        // select the next button from previous
                        nextFocus = `${id}-date-selector-nav-next`;
                    } else if (
                        currentFocus.includes('date-selector-nav-next')
                    ) {
                        // trigger next
                        onNext();
                    } else if (
                        currentNumber !== undefined &&
                        currentFocus !== lastEnabledDate
                    ) {
                        // select the next enabled date
                        nextFocus = `${id}-date-selector-date-${
                            currentNumber + 1
                        }`;
                    }
                } else if (event.key === 'ArrowLeft' && currentFocus) {
                    if (currentFocus.includes('date-selector-nav-next')) {
                        // select previous
                        nextFocus = `${id}-date-selector-nav-previous`;
                    } else if (
                        currentFocus.includes('date-selector-nav-previous')
                    ) {
                        // if you can go previous, go previous
                        onPrevious();
                    } else if (
                        currentNumber !== undefined &&
                        currentFocus !== firstEnabledDate
                    ) {
                        // select the previous enabled date
                        nextFocus = `${id}-date-selector-date-${
                            currentNumber - 1
                        }`;
                    }
                }

                if (nextFocus) {
                    const element: HTMLButtonElement | null =
                        document.querySelector(`#${nextFocus}`);

                    if (element && !element.disabled) {
                        element.focus();
                        setCurrentFocus(nextFocus);
                    }
                }
            }
        };
        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
    }, [
        currentFocus,
        date,
        id,
        isNextDisabled,
        isPreviousDisabled,
        onNext,
        onPrevious,
    ]);

    const panel = (
        <>
            <MobilePanelHeader
                label={t('search.selectADate')}
                onClick={onToggle}
            />
            <div className="flex w-full flex-row items-center justify-start gap-x-2 border-b p-3 sm:hidden">
                <FontAwesomeIcon icon={['far', 'calendar']} />
                <div className="">
                    {date ? toFullDate(date, locale) : t('search.selectADate')}
                </div>
            </div>
            <div
                className={clsx(
                    'min-w-[22rem] max-w-[22rem]',
                    'bg-body z-30 mx-auto mt-3 overflow-hidden rounded border border-transparent pb-2',
                    'sm:border-outline sm:absolute sm:mt-2 sm:pt-1 sm:shadow-lg'
                )}
            >
                <div className="px-2">
                    <DateSelectorNav
                        id={id}
                        onNext={isNextDisabled ? undefined : onNext}
                        onPrev={isPreviousDisabled ? undefined : onPrevious}
                        visibleDate={visibleDate}
                    />
                    <DateSelectorCalendar
                        availabilityDates={availabilityDates}
                        currentFocus={currentFocus}
                        date={date}
                        id={id}
                        maxDate={maxDate}
                        minDate={minDate}
                        onDateClick={onDateClick}
                        setCurrentFocus={setCurrentFocus}
                        visibleDate={visibleDate}
                    />
                    {availabilityDates && (
                        <div className="mt-2 flex justify-between border-t border-grey-300 px-1.5 pt-2">
                            <div className="flex items-center">
                                <div className="h-4 w-4 rounded-full border-2 border-gold-200" />
                                <span className="ml-2 text-xs">
                                    {t('search.seatsAvailable')}
                                </span>
                            </div>
                            <div className="flex items-center">
                                <div className="h-4 w-4 rounded-full border-2 border-grey-500 bg-grey-100 dark:border-grey-700 dark:bg-grey-800" />
                                <span className="ml-2 text-xs">
                                    {t('search.waitlistOnly')}
                                </span>
                            </div>
                        </div>
                    )}
                    {isClearable && isDesktop && (
                        <Button
                            className="mt-4 w-full"
                            kind="tertiary"
                            onClick={onClearDate}
                        >
                            {t('form.clear')}
                        </Button>
                    )}
                </div>
            </div>
            {!isCloseOnSelect && (
                <div className="border-outline absolute inset-x-0 bottom-0 mt-4 grid grid-cols-3 justify-center gap-x-2 border-t p-4 sm:hidden">
                    {isClearable && (
                        <Button
                            className="col-span-1"
                            kind="tertiary"
                            onClick={onClearDate}
                        >
                            {t('form.clear')}
                        </Button>
                    )}
                    <Button
                        className={`${
                            isClearable ? 'col-span-2' : 'col-span-full'
                        }`}
                        onClick={onToggle}
                    >
                        {t('form.selectDate')}
                    </Button>
                </div>
            )}
        </>
    );

    if (isDesktop) return panel;
    const portal = document.querySelector('#portal');
    const portalDiv = (
        <div className="bg-body relative h-full w-full">{panel}</div>
    );

    return portal ? createPortal(portalDiv, portal) : null;
};

export default DateSelectorPanel;
