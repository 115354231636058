import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import Server404 from 'components/Errors/Server404';
import FallbackImage from 'components/FallbackImage';
import Form from 'components/Form';
import SubmitButton from 'components/Form/SubmitButton';
import TextArea from 'components/Form/TextArea';
import Link from 'components/Link';
import Spinner from 'components/Loaders/Spinner';
import {
    CancelReservationDocument,
    ReservationCancelDocument,
    ReservationCancelQuery,
    ReservationStatus as GqlReservationStatus,
} from 'gql/generated';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {useLocale} from 'state/locale';
import {useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';
import {
    fromIOS8601DateTimeString,
    isAfterSixPmDayBefore,
    toFullDate,
    toISO8601Date,
    toTime,
} from 'utils/date';
import {withValues} from 'utils/object';
import {ReservationStatus} from '../ListElement/ReservationStatus';

type CancelFormData = FormData<{reason: string}>;

export const CancelReservation: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();
    const breakpoint = useCurrentBreakpoint();
    const navigate = useNavigateLocale();
    const showFullDate = breakpoint !== 'md';
    const {reservationId} = useParams();
    const {toastWrapper} = useToastNotification();

    const {data, error, loading} = useQuery<ReservationCancelQuery>(
        ReservationCancelDocument,
        {
            variables: {id: reservationId},
        }
    );

    const methods = useForm<CancelFormData>({defaultValues: {reason: ''}});
    const {handleSubmit, watch} = methods;
    const reason = watch('reason');

    const [cancel, {loading: cancelLoading}] = useMutation(
        CancelReservationDocument
    );

    const onSubmit = async (formData: CancelFormData) => {
        await toastWrapper({
            onFulfilled: () => navigate('/account/reservations'),
            promise: cancel({
                variables: withValues({
                    cancelReason: formData.reason,
                    id: reservationId,
                }),
            }),
            successMessage: t('user.reservations.cancellation.confirmation'),
        });
    };

    if (error) {
        return <Server404 />;
    }

    if (loading || !data?.reservation) {
        return <Spinner title="Loading Reservation" />;
    }

    const {
        cancellationInfo,
        cancellationPossible,
        dateTime,
        numberOfPeople,
        status,
        venue,
        waitingListRequest,
    } = data.reservation;

    const parsedDateTime = fromIOS8601DateTimeString(dateTime) as Date;

    const reservationDate = showFullDate
        ? toFullDate(parsedDateTime, locale)
        : toISO8601Date(parsedDateTime);

    const guestIcon =
        numberOfPeople === 1
            ? 'user'
            : numberOfPeople === 2
            ? 'user-friends'
            : 'users';

    const venueImage = venue.thumbnail || venue.imageUrls[0];

    // only confirmed reservations where it is after 6pm the day before visit are shown the alert
    const displayAlert =
        !cancellationPossible &&
        status === GqlReservationStatus.Confirmed &&
        isAfterSixPmDayBefore(new Date(dateTime));

    return (
        <div className="flex flex-col gap-4 px-2 md:px-0">
            <h2 className="text-2xl">
                {waitingListRequest
                    ? t('user.reservations.cancellation.titleWaitlist')
                    : t('user.reservations.cancellation.title')}
            </h2>
            <h3 className="text-xl">
                {waitingListRequest
                    ? t('user.reservations.cancellation.subTitleWaitlist')
                    : t('user.reservations.cancellation.subTitle')}
            </h3>
            <div className="border-outline rounded-md border p-2.5 md:px-4 md:pb-4">
                <h3 className="border-outline hidden min-h-[2.8125rem] border-b pb-2.5 md:flex md:items-center md:justify-between md:gap-4">
                    <Link className="link-body" to={`/restaurants/${venue.id}`}>
                        <span className="line-clamp-2 font-semibold leading-snug">
                            {venue.name}
                        </span>
                    </Link>
                </h3>
                <div className="grid grid-cols-12 gap-4 md:mt-2.5">
                    <div className="col-span-4 lg:col-span-3">
                        <figure className="relative max-h-[12.375rem]">
                            {venueImage ? (
                                <div className="w-full pb-[56.5%]">
                                    <picture>
                                        <img
                                            alt={venue.name}
                                            className="absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center"
                                            src={venueImage}
                                        />
                                    </picture>
                                </div>
                            ) : (
                                <div className="w-full pb-[56.5%]">
                                    <picture>
                                        <FallbackImage
                                            alt={venue.name}
                                            className="absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center"
                                        />
                                    </picture>
                                </div>
                            )}
                        </figure>
                    </div>
                    <h3 className="col-span-8 md:hidden">
                        <Link
                            className="link-body"
                            to={`/restaurants/${venue.id}`}
                        >
                            <span className="line-clamp-3 font-semibold leading-snug">
                                {venue.name}
                            </span>
                        </Link>
                    </h3>
                    <hr className="col-span-full -mt-2 md:hidden" />
                    <div className="col-span-full -mt-2 grid grid-cols-1 items-start gap-4 md:col-span-8 md:mt-0 md:grid-cols-12 md:gap-2 lg:col-span-9 lg:gap-4">
                        <div className="col-span-1 grid grid-cols-1 gap-3.5 md:col-span-4 md:pt-3 lg:col-span-5">
                            <div className="flex items-center text-sm">
                                <div className="w-5 text-center">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['far', 'calendar']}
                                    />
                                </div>
                                <span className="ml-2.5">
                                    {reservationDate}
                                </span>
                            </div>
                            <div className="flex items-center text-sm">
                                <div className="w-5 text-center">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['fas', 'clock']}
                                    />
                                </div>
                                <span className="ml-2.5">
                                    {waitingListRequest ? (
                                        <>
                                            {/* Backend issue: we need to use reservation.dateTime
                                            instead of waitingListRequest.dateTimeRangeStart
                                            https://github.com/pocket-concierge/pokeme_rails/pull/9854#issuecomment-1384042575
                                        */}
                                            {toTime(parsedDateTime, locale)}
                                            &nbsp;-&nbsp;
                                            {toTime(
                                                fromIOS8601DateTimeString(
                                                    waitingListRequest.dateTimeRangeEnd
                                                ) as Date,
                                                locale
                                            )}
                                        </>
                                    ) : (
                                        toTime(parsedDateTime, locale)
                                    )}
                                </span>
                            </div>
                            <div className="flex items-center text-sm">
                                <div className="w-5 text-center">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['fas', guestIcon]}
                                    />
                                </div>
                                <span className="ml-2.5">
                                    {t('reservation.guestCount', {
                                        count: numberOfPeople,
                                    })}
                                </span>
                            </div>
                        </div>
                        <ReservationStatus
                            className="col-span-1 md:col-span-8 lg:col-span-7"
                            status={status}
                            waitlistDeadline={waitingListRequest?.deadline}
                        />
                    </div>
                    {cancellationPossible && (
                        <>
                            {cancellationInfo && (
                                <div className="col-span-full flex justify-center bg-red-100 py-2 font-semibold text-red-800">
                                    {t('user.reservations.cancellation.fee', {
                                        amount: cancellationInfo.amount,
                                        percentage: cancellationInfo.percentage,
                                    })}
                                </div>
                            )}
                            {!waitingListRequest && (
                                <>
                                    <div className="col-span-full">
                                        <Trans
                                            components={{
                                                Link: (
                                                    <Link key={0} to="/terms/">
                                                        {' '}
                                                    </Link>
                                                ),
                                            }}
                                            i18nKey="user.reservations.cancellation.notice"
                                        />
                                    </div>
                                    <h3 className="border-outline col-span-full border-b pb-2 text-sm">
                                        {t(
                                            'user.reservations.cancellation.reason'
                                        )}
                                    </h3>
                                </>
                            )}
                            <div className="col-span-full">
                                <FormProvider {...methods}>
                                    <Form
                                        className={`${
                                            waitingListRequest
                                                ? ''
                                                : 'bg-step p-4'
                                        }`}
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        {!waitingListRequest && (
                                            <TextArea
                                                disabled={cancelLoading}
                                                label={t(
                                                    'user.reservations.cancelReason'
                                                )}
                                                maxLength={1000}
                                                name="reason"
                                                required={true}
                                                rows={2}
                                            />
                                        )}
                                        <div className="flex justify-between">
                                            <Button
                                                className="min-h-[42px] shrink"
                                                disabled={cancelLoading}
                                                kind="secondary"
                                                onClick={() =>
                                                    navigate(
                                                        '/account/reservations'
                                                    )
                                                }
                                            >
                                                {t(
                                                    'user.reservations.button.back'
                                                )}
                                            </Button>
                                            <SubmitButton
                                                className="shrink"
                                                disabled={
                                                    reason.trim() === '' &&
                                                    !waitingListRequest
                                                }
                                                kind="destructive"
                                            >
                                                {waitingListRequest
                                                    ? t(
                                                          'user.reservations.button.withdraw'
                                                      )
                                                    : t(
                                                          'user.reservations.button.cancelReservation'
                                                      )}
                                            </SubmitButton>
                                        </div>
                                    </Form>
                                </FormProvider>
                            </div>
                        </>
                    )}
                    {displayAlert && (
                        <div
                            className="bg-step col-span-full flex flex-row items-center rounded-md border border-orange-800 p-1 text-sm dark:border-orange-700 md:text-base"
                            role="alert"
                        >
                            <FontAwesomeIcon
                                className="pr-2 text-orange-800 dark:text-orange-700"
                                icon={['fas', 'exclamation-circle']}
                                size="1x"
                            />
                            <div className="text-sub text-center">
                                {t(
                                    'user.reservations.cancelDayBeforeInformation',
                                    {
                                        tel: venue.phoneNumber,
                                    }
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
