import {Route} from 'react-router-dom';
import Server404 from 'components/Errors/Server404';
import MenuSidebarLayout from 'components/Layout/MenuSideBarLayout';
import PageLayout from 'components/Layout/PageLayout';
import Bookmarks from 'components/User/Account/Bookmarks';
import UserPaymentMethods from 'components/User/Account/PaymentMethods/PaymentMethods';
import {Profile} from 'components/User/Account/Profile/Profile';
import {CancelReservation} from 'components/User/Account/Reservations/CancelReservation/CancelReservation';
import {Message} from 'components/User/Account/Reservations/Message/Message';
import {ReservationList} from 'components/User/Account/Reservations/ReservationList';
import {Settings} from 'components/User/Account/Settings/Settings';
import userAccountSections from 'components/User/Account/user-account-sections';
import {Analytics, PageName} from 'pages/Analytics';

const AccountRoutes = () => (
    <Route element={<PageLayout isAuthenticatedPage={true} />}>
        <Route path="account/*">
            <Route element={<Server404 />} path="*" />
            <Route
                element={
                    <MenuSidebarLayout
                        sections={userAccountSections}
                        showHeader={true}
                    />
                }
            >
                <Route
                    element={
                        <Analytics pageName={PageName.Bookmarks}>
                            <Bookmarks />
                        </Analytics>
                    }
                    path="bookmarks"
                />
                <Route
                    element={
                        <Analytics pageName={PageName.Reservations}>
                            <ReservationList />
                        </Analytics>
                    }
                    path="reservations"
                />
                <Route path="reservations/:reservationId">
                    <Route
                        element={
                            <Analytics pageName={PageName.Cancel}>
                                <CancelReservation />
                            </Analytics>
                        }
                        path="cancel"
                    />
                    <Route
                        element={
                            <Analytics pageName={PageName.Message}>
                                <Message />
                            </Analytics>
                        }
                        path="message"
                    />
                </Route>
                <Route
                    element={
                        <Analytics pageName={PageName.Profile}>
                            <Profile />
                        </Analytics>
                    }
                    index={true}
                    path="profile"
                />
                <Route
                    element={
                        <Analytics pageName={PageName.PaymentMethods}>
                            <UserPaymentMethods />
                        </Analytics>
                    }
                    path="payment-methods"
                />
                <Route
                    element={
                        <Analytics pageName={PageName.Settings}>
                            <Settings />
                        </Analytics>
                    }
                    path="settings"
                />
            </Route>
        </Route>
    </Route>
);

export default AccountRoutes;
