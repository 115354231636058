import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import ServiceError from 'components/Errors/ServiceError';
import NoResults from 'components/NoResults';
import PaymentMethod from 'components/PaymentMethod';
import Stripe from 'components/Stripe';
import {CreditCardSearchDocument, CreditCardSearchQuery} from 'gql/generated';
import {noop} from 'lodash';
import UserPaymentMethodsSkeleton from './PaymentMethodsSkeleton';
import UserPaymentStripeForm from './PaymentStripeForm';

const UserPaymentMethods: FC = () => {
    const {t} = useTranslation();

    const {data, error, loading} = useQuery<CreditCardSearchQuery>(
        CreditCardSearchDocument,
        {
            variables: {
                includeExpired: true,
            },
        }
    );

    const creditCards = data?.creditCardSearch;

    return (
        <div className="bg-body md:border-outline mt-4 px-4 md:rounded-md md:border md:p-4 lg:p-5">
            <h2 className="px-4 pb-1 text-base font-semibold md:block md:px-0">
                {t('user.paymentMethods.addNewCard')}
            </h2>
            <hr className="w-full py-2" />
            {loading ? (
                <UserPaymentMethodsSkeleton />
            ) : error ? (
                <ServiceError className="mt-4" error={error} />
            ) : (
                <Stripe>
                    <div className="bg-step mb-8 rounded-md p-4">
                        <UserPaymentStripeForm />
                    </div>
                    <h2 className="px-4 pb-1 text-base font-semibold md:block md:px-0">
                        {t('user.paymentMethods.cardList')}
                    </h2>
                    <hr className="w-full py-2" />
                    {!!creditCards?.length && creditCards.length > 0 ? (
                        <div className="bg-step flex flex-col justify-items-center divide-y divide-grey-200 rounded-md px-6 py-4 dark:divide-grey-700">
                            {creditCards?.map((item) => (
                                <PaymentMethod
                                    key={item.id}
                                    card={item}
                                    onRemove={noop}
                                />
                            ))}
                        </div>
                    ) : (
                        <NoResults
                            icon="reservations"
                            label={t('user.paymentMethods.noCards')}
                        />
                    )}
                </Stripe>
            )}
        </div>
    );
};

export default UserPaymentMethods;
