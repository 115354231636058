import {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {trackClick} from 'utils/analytics';

type MarketingCardProps = {
    assetName?: string;
    className?: string;
    imageClassName?: string;
    imageURL: string;
    isExternal?: boolean;
    path: string;
};

const MarketingCard: FC<MarketingCardProps> = ({
    assetName,
    className = '',
    imageClassName = '',
    imageURL,
    isExternal = false,
    path,
}) => {
    const [imageError, setImageError] = useState(false);
    const imageName = imageURL.split('/')?.pop() || 'marketing-material';

    if (imageError) {
        return null;
    }

    return (
        <Link
            aria-label={imageName}
            className={clsx(
                'border-venue-result flex select-none flex-col overflow-hidden rounded',
                className
            )}
            data-dd-action-name={assetName || imageName}
            onClick={() => trackClick(assetName || imageName)}
            rel={isExternal ? 'noopener noreferrer' : undefined}
            reloadDocument={true}
            target={isExternal ? '_blank' : undefined}
            to={path}
        >
            <figure className="relative">
                <picture>
                    <img
                        alt={imageName}
                        className={imageClassName}
                        loading="lazy"
                        onError={() => setImageError(true)}
                        src={imageURL}
                    />
                </picture>
            </figure>
        </Link>
    );
};

export default MarketingCard;
