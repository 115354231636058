import {FC} from 'react';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {getS3ImageURL} from 'utils/environment';

export type SkeletonImageProps = {
    // eslint-disable-next-line react/boolean-prop-naming
    roundedBottom?: boolean;
    size?: SizeProp;
    step?: boolean;
};

const SkeletonImage: FC<SkeletonImageProps> = ({
    roundedBottom = true,
    size = '10x',
    step,
}) => (
    <figure
        className={clsx('skeleton relative max-h-[12.375rem]', step && 'step')}
    >
        <div className="w-full pb-[56.5%]">
            <picture>
                <img
                    alt="img"
                    className={clsx(
                        'absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center',
                        !roundedBottom && 'rounded-b-none'
                    )}
                    src={getS3ImageURL('transparent', 'png')}
                />
            </picture>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
            <FontAwesomeIcon
                className="text-grey-50 dark:text-grey-900"
                icon={['far', 'image']}
                size={size}
            />
        </div>
    </figure>
);

export default SkeletonImage;
