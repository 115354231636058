import {FC} from 'react';
import Spinner from 'components/Loaders/Spinner';
import {useLocale} from 'state/locale';
import {getFAQLink} from 'utils/external-links';

export const ContactFormPage: FC = () => {
    const locale = useLocale();

    // If a user directly goes to /contact they will be redirected to the Salesforce page
    window.location.replace(getFAQLink(locale, 'contactsupport'));

    // In the meantime until the new side is loaded we show a loading icon
    return <Spinner className="h-full" size="5x" title="Redirecting" />;
};
