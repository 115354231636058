import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
    differenceInMinutes,
    eachMinuteOfInterval,
    isAfter,
    isBefore,
    isSameHour,
    isSameMinute,
    max,
    min,
    parseISO,
} from 'date-fns';
import {
    Availability,
    Course,
    ReservableAvailability,
    SeatingType,
    ServiceType,
} from 'gql/generated';
import {VenueValues} from 'types/search';
import {getRange} from 'utils/array';
import {toISO8601Date, toJST, toTime24} from 'utils/date';

const MIN_DATE = new Date(0);
const MAX_DATE = new Date(9_000_000_000_000);

const MINUTES_INTERVAL = 30;

type Props = {
    availabilities: Availability[] | undefined | null;
    isWaitlist: boolean;
};

const isSameTime = (date1: Date, date2: Date) =>
    isSameHour(date1, date2) && isSameMinute(date1, date2);

export const useReservationValues = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Initialize data for "date", "partySize", and "serviceType"
    useEffect(() => {
        const searchParameterDate = searchParams.get('date');
        const searchParameterPartySize = searchParams.get('partySize');
        const searchParamServiceType = searchParams.get('serviceType');

        const initialValues: Record<string, string> = {};

        if (!searchParameterDate) {
            initialValues.date = toISO8601Date(toJST(new Date()));
        }

        if (!searchParameterPartySize) {
            initialValues.partySize = '2';
        }

        if (!searchParamServiceType) {
            initialValues.serviceType = ServiceType.Dinner;
        }

        if (Object.keys(initialValues).length > 0) {
            setSearchParams(
                (prev) => {
                    Object.entries(initialValues).forEach(([key, value]) => {
                        prev.set(key, value);
                    });

                    return prev;
                },
                {replace: true}
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setValue = useCallback(
        (key: keyof VenueValues, value: string | Date | undefined) => {
            if (value) {
                // return early if the value wouldn't change
                if (searchParams.get(key) === value) {
                    return;
                }

                setSearchParams(
                    (prev) => {
                        if (key === 'date' || key === 'waitlistDeadline') {
                            prev.set(key, toISO8601Date(value as Date));
                        } else if (
                            key === 'time' ||
                            key === 'waitlistArrivalStart' ||
                            key === 'waitlistArrivalEnd'
                        ) {
                            prev.set(key, toTime24(value as Date));
                        } else {
                            prev.set(key, value as string);
                        }

                        return prev;
                    },
                    {replace: true}
                );
            }
            // Only delete a search param if the key exists
            else if (searchParams.get(key)) {
                setSearchParams(
                    (prev) => {
                        prev.delete(key);

                        return prev;
                    },
                    {replace: true}
                );
            }
        },
        [searchParams, setSearchParams]
    );

    const getValue = useCallback(
        (key: keyof VenueValues) => {
            const value = searchParams.get(key);

            if (!value) return null;

            if (key === 'date' || key === 'waitlistDeadline') {
                return toJST(new Date(`${String(value)}`));
            }

            if (
                key === 'time' ||
                key === 'waitlistArrivalStart' ||
                key === 'waitlistArrivalEnd'
            ) {
                const selectedDate = searchParams.get('date');
                const [hours, minutes] = value.split(':');

                return selectedDate
                    ? new Date(
                          `${selectedDate}T${hours}:${minutes}:00.000+09:00`
                      )
                    : toJST(new Date());
            }

            return value;
        },
        [searchParams]
    );

    const resetValues = useCallback(() => {
        setSearchParams(
            (prev) => {
                prev.delete('seatingType');
                prev.delete('time');
                prev.delete('waitlistArrivalStart');
                prev.delete('waitlistArrivalEnd');
                prev.delete('waitlistDeadline');

                return prev;
            },
            {replace: true}
        );
    }, [setSearchParams]);

    const getValues = useCallback(() => {
        return {
            date: (getValue('date') || toJST(new Date())) as Date,
            partySize: (getValue('partySize') || '2') as string,
            seatingType: getValue('seatingType') as SeatingType | undefined,
            serviceType: (getValue('serviceType') ||
                ServiceType.Dinner) as ServiceType,
            time: getValue('time') as Date | undefined,
            waitlistArrivalEnd: getValue('waitlistArrivalEnd') as
                | Date
                | undefined,
            waitlistArrivalStart: getValue('waitlistArrivalStart') as
                | Date
                | undefined,
            waitlistDeadline: getValue('waitlistDeadline') as Date | undefined,
        };
    }, [getValue]);

    return {
        getValue,
        getValues,
        resetValues,
        setValue,
    };
};

const useFilter = ({availabilities, isWaitlist}: Props) => {
    const [isSubmittable, setIsSubmittable] = useState(false);

    const {getValues, resetValues, setValue} = useReservationValues();

    const {
        date,
        partySize,
        seatingType,
        serviceType,
        time,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    } = getValues();

    // Uses only the courses in the availability search to show in the ReservationWidget
    const allAvailableCourses =
        availabilities?.reduce((acc, availability) => {
            if (acc.findIndex(({id}) => id === availability.course.id) === -1) {
                acc.push(availability.course);
            }

            return acc;
        }, [] as Course[]) || [];

    const guestRange = useMemo(() => {
        const temporaryRange =
            availabilities &&
            availabilities.reduce(
                (acc, availability) =>
                    [
                        ...new Set([
                            ...acc,
                            ...getRange(
                                availability.minPartySize,
                                availability.maxPartySize
                            ),
                        ]),
                    ].sort((a, b) => a - b),
                [] as number[]
            );

        if (!temporaryRange) return [];

        return temporaryRange;
    }, [availabilities]);

    // Reset Time and SeatingType when changing to a waitlist reservation
    useEffect(() => {
        if (isWaitlist && (time || seatingType)) {
            setValue('time', undefined);
            setValue('seatingType', undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitlist, seatingType, time]);

    // Keep track of changes related available party size slots
    useEffect(() => {
        if (
            !guestRange ||
            guestRange.length === 0 ||
            guestRange.includes(Number(partySize))
        )
            return;

        if (guestRange.length === 1) {
            const onlyValue = String(guestRange[0]);

            // if there is only one partySize to select, automatically select it
            if (partySize !== onlyValue) {
                setValue('partySize', onlyValue);
            }
        } else if (!guestRange.includes(Number(partySize))) {
            // if a partySize of 2 is not available set it to 1. Otherwise try 3, 4, 5, ...
            if (guestRange.includes(2)) {
                setValue('partySize', '2');
            } else if (guestRange.includes(1)) {
                setValue('partySize', '1');
            } else {
                setValue(
                    'partySize',
                    String(guestRange.find((size) => size > 2))
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, guestRange, partySize]);

    useEffect(() => {
        if (
            (date && partySize && serviceType && time && seatingType) ||
            (waitlistArrivalStart && waitlistArrivalEnd && waitlistDeadline)
        ) {
            setIsSubmittable(true);
        } else {
            setIsSubmittable(false);
        }
    }, [
        date,
        partySize,
        seatingType,
        serviceType,
        time,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    ]);

    let filteredAvailabilities = availabilities?.filter(
        (availability) =>
            Number(partySize) >= availability.minPartySize &&
            Number(partySize) <= availability.maxPartySize
    );

    // Time (Service Type)
    const availableServiceTypes = useMemo(
        () => [
            ...new Set(
                filteredAvailabilities?.map(
                    (availability) => availability.course.serviceType
                )
            ),
        ],
        [filteredAvailabilities]
    );

    // Automatically select the only available service type
    useEffect(() => {
        if (
            serviceType &&
            availableServiceTypes.length > 0 &&
            !(serviceType && availableServiceTypes.includes(serviceType))
        ) {
            setValue('serviceType', availableServiceTypes[0]);
        } else if (!serviceType) {
            if (availableServiceTypes.includes(ServiceType.Dinner)) {
                setValue('serviceType', ServiceType.Dinner);
            } else {
                setValue('serviceType', ServiceType.Lunch);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableServiceTypes, partySize, serviceType]);

    useEffect(() => {
        if (!isWaitlist) return;

        setValue('waitlistArrivalStart', undefined);
        setValue('waitlistArrivalEnd', undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitlist, serviceType]);

    filteredAvailabilities = filteredAvailabilities?.filter(
        (availability) => availability.course.serviceType === serviceType
    );

    // For waitlists
    const interval = useMemo(
        () =>
            filteredAvailabilities?.reduce(
                (acc, availability) => {
                    acc.start = min([
                        acc.start,
                        parseISO(availability.startTime),
                    ]);
                    acc.end = max([acc.end, parseISO(availability.endTime)]);

                    return acc;
                },
                {end: MIN_DATE, start: MAX_DATE}
            ) as {
                end: Date;
                start: Date;
            },
        [filteredAvailabilities]
    );

    // Courses which are selectable by changing time or seating type
    const availableCourseIds =
        [
            ...new Set(
                filteredAvailabilities?.map(
                    (availability) => availability.course.id
                )
            ),
        ] || [];

    // For time slots
    const timeSeries = useMemo(() => {
        if (!filteredAvailabilities || isWaitlist) return [];

        const result = filteredAvailabilities.reduce((acc, availability) => {
            const temporaryInterval = {
                end: parseISO(availability.endTime),
                start: parseISO(availability.startTime),
            };

            if (
                differenceInMinutes(
                    temporaryInterval.end,
                    temporaryInterval.start
                ) < MINUTES_INTERVAL
            ) {
                return [...acc, temporaryInterval.start];
            }

            const availableTimes = eachMinuteOfInterval(
                {
                    end: temporaryInterval.end,
                    start: temporaryInterval.start,
                },
                {
                    step: MINUTES_INTERVAL,
                }
            );

            return [...acc, ...availableTimes];
        }, [] as Date[]);

        return [
            ...new Set(
                result.map((temporaryResult) => temporaryResult.getTime())
            ),
        ]
            .sort((a, b) => a - b)
            .map((temporaryTime) => new Date(temporaryTime));
    }, [filteredAvailabilities, isWaitlist]);

    const highlightsInterval = useMemo(() => {
        if (isWaitlist) return undefined;

        const temporaryInterval = filteredAvailabilities?.filter(
            (availability) =>
                (availability as ReservableAvailability).seatingType ===
                seatingType
        );

        if (!temporaryInterval || temporaryInterval.length === 0)
            return undefined;

        return temporaryInterval.reduce(
            (acc, availability) => {
                acc.start = min([acc.start, parseISO(availability.startTime)]);
                acc.end = max([acc.end, parseISO(availability.endTime)]);

                return acc;
            },
            {end: MIN_DATE, start: MAX_DATE}
        );
    }, [filteredAvailabilities, isWaitlist, seatingType]);

    useEffect(() => {
        // No need to update time because it is not available for waitlists
        if (isWaitlist) return;

        setValue('time', undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitlist, partySize, serviceType]);

    if (time) {
        filteredAvailabilities = filteredAvailabilities?.filter(
            (availability) =>
                isSameTime(time, parseISO(availability.startTime)) ||
                isSameTime(time, parseISO(availability.endTime)) ||
                (isAfter(time, parseISO(availability.startTime)) &&
                    isBefore(time, parseISO(availability.endTime)))
        );
    }

    const seatingOptions = useMemo(
        () => [
            ...new Set(
                availabilities
                    ?.filter(
                        (availability) =>
                            availability.course.serviceType === serviceType &&
                            Number(partySize) >= availability.minPartySize &&
                            Number(partySize) <= availability.maxPartySize
                    )
                    ?.map(
                        (availability) =>
                            (availability as ReservableAvailability).seatingType
                    )
            ),
        ],
        [availabilities, partySize, serviceType]
    );

    const availableSeatingOptions = useMemo(
        () => [
            ...new Set(
                filteredAvailabilities?.map(
                    (availability) =>
                        (availability as ReservableAvailability).seatingType
                )
            ),
        ],
        [filteredAvailabilities]
    );

    if (!isWaitlist) {
        filteredAvailabilities = filteredAvailabilities?.filter(
            (availability) =>
                (availability as ReservableAvailability).seatingType ===
                seatingType
        );
    }

    useEffect(() => {
        // No need to update seating options because they are not available for waitlists
        if (isWaitlist) return;

        if (seatingType && !availableSeatingOptions.includes(seatingType)) {
            setValue('seatingType', undefined);
        } else if (!seatingType && availableSeatingOptions.length === 1) {
            setValue('seatingType', availableSeatingOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableSeatingOptions, isWaitlist, seatingType]);

    // Reset Seating Options when Party Size changes
    useEffect(() => {
        if (isWaitlist) return;

        setValue('seatingType', undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitlist, partySize]);

    // Courses which are selectable based on their selection (Guest size + service type + time + seating type)
    const selectableCourseIds =
        [
            ...new Set(
                filteredAvailabilities?.map(
                    (availability) => availability.course.id
                )
            ),
        ] || [];

    return {
        allAvailableCourses,
        availableCourseIds,
        availableSeatingOptions,
        availableServiceTypes,
        date,
        guestRange,
        highlightsInterval,
        interval,
        isSubmittable,
        partySize,
        resetValues,
        seatingOptions,
        seatingType,
        selectableCourseIds,
        serviceType,
        setValue,
        time,
        timeSeries,
        waitlistArrivalEnd,
        waitlistArrivalStart,
        waitlistDeadline,
    };
};

export default useFilter;
