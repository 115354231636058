import {Locale} from 'types';
import {getS3ImageURL} from 'utils/environment';

type MarketingContent = {
    assetName: string;
    imageUrl: string;
    isExternal: boolean;
    path: string;
};

export type MarketingContentGroup = {
    [locale in Locale]: MarketingContent[];
};

export const firstRow: MarketingContentGroup = {
    en: [
        {
            assetName: 'Ginza',
            imageUrl: getS3ImageURL('panel-article-ginza', 'webp'),
            isExternal: true,
            path: 'https://pocket-concierge.jp/lp/amex/gms/pocket_ginza/index.html?intlink=jp-ICS-Top_panel_en_Ginza',
        },
        {
            assetName: 'Azabu',
            imageUrl: getS3ImageURL('panel-article-azabu', 'webp'),
            isExternal: true,
            path: 'https://pocket-concierge.jp/lp/amex/gms/pocket_azabu/index.html?intlink=jp-ICS-Top_panel_en_Azabu',
        },
        {
            assetName: 'Gion',
            imageUrl: getS3ImageURL('panel-article-gion', 'webp'),
            isExternal: true,
            path: 'https://pocket-concierge.jp/lp/amex/gms/pocket_gion/index.html?intlink=jp-ICS-Top_panel_en_Gion',
        },
        {
            assetName: 'Osaka',
            imageUrl: getS3ImageURL('panel-article-osaka', 'webp'),
            isExternal: true,
            path: 'https://pocket-concierge.jp/lp/amex/gms/pocket_osaka/index.html?intlink=jp-ICS-Top_panel_en_Osaka',
        },
    ],
    ja: [
        {
            assetName: 'Amex Curation',
            imageUrl: getS3ImageURL('top_panel_plat_curation_banner', 'webp'),
            isExternal: true,
            path: '/lp/amex/benefit/curation/index.html?extlink=va-jp-ICS-Top_panel_Amex_Curation',
        },
        {
            assetName: 'Amex Gold Preferred Card',
            imageUrl: getS3ImageURL('home_page_gold_preferred_card', 'webp'),
            isExternal: true,
            path: '/lp/amex/benefit/gold_curation/index.html?extlink=va-jp-ICS-Top_panel_gold_curation',
        },
        {
            assetName: 'Amex Business Platinum Card',
            imageUrl: getS3ImageURL('top-panel-business-platinum', 'webp'),
            isExternal: true,
            path: '/lp/amex/benefit/sbsplat_curation/index.html?extlink=va-jp-ICS-Top_panel_Amex_Curation_SBSPLAT',
        },
        {
            assetName: 'French',
            imageUrl: getS3ImageURL('home_page_4', 'webp'),
            isExternal: false,
            path: '/restaurants?cuisine=8&extlink=va-jp-ICS-Top_panel_NFE_french',
        },
    ],
};

export const secondRow: MarketingContentGroup = {
    en: [
        {
            assetName: 'Sushi',
            imageUrl: getS3ImageURL('popular-cuisine-en-sushi', 'webp'),
            isExternal: false,
            path: '/en/restaurants/?cuisine=2&extlink=va-jp-ICS-Top_panel_NFE_Sushi_en',
        },
        {
            assetName: 'Japanese',
            imageUrl: getS3ImageURL('popular-cuisine-en-japanese', 'webp'),
            isExternal: false,
            path: '/en/restaurants/?cuisine=1&extlink=va-jp-ICS-Top_panel_NFE_Japanese_en',
        },
        {
            assetName: 'French',
            imageUrl: getS3ImageURL('popular-cuisine-en-french', 'webp'),
            isExternal: false,
            path: '/en/restaurants/?cuisine=8&extlink=va-jp-ICS-Top_panel_NFE_French_en',
        },
        {
            assetName: 'Yaniniku',
            imageUrl: getS3ImageURL('popular-cuisine-en-yakiniku', 'webp'),
            isExternal: false,
            path: '/en/restaurants/?cuisine=5&extlink=va-jp-ICS-Top_panel_NFE_Yakiniku_en',
        },
    ],
    ja: [
        {
            assetName: 'Special Occasion',
            imageUrl: getS3ImageURL('記念日_v3', 'webp'),
            isExternal: true,
            path: 'https://pocket-concierge.jp/lp/hottopic/feature/newlife2023/index.html?intlink=jp-ICS-Top_panel_Special_Occasion',
        },
        {
            assetName: 'Business Use',
            imageUrl: getS3ImageURL('接待_v3', 'webp'),
            isExternal: true,
            path: 'https://pocket-concierge.jp/lp/curations/entertaining_guests/index.html?intlink=jp-ICS-Top_panel_Business_Use',
        },
        {
            assetName: 'Rising Restaurants',
            imageUrl: getS3ImageURL('人気上昇_v3', 'webp'),
            isExternal: true,
            path: 'https://blog.pocket-concierge.jp/rising_restaurants_202307/?intlink=jp-ICS-Top_panel_Rising_Restaurants',
        },
        {
            assetName: 'Sushi Differences',
            imageUrl: getS3ImageURL('赤シャリと白シャリの違い', 'webp'),
            isExternal: true,
            path: 'https://blog.pocket-concierge.jp/differences-between-akashari-siroshari/?intlink=jp-ICS-Top_panel_Blog_Akashari',
        },
    ],
};

type PickUpVenueContent = {
    [venueId: string]: {
        order: number;
        suffix: string;
    };
};

export type PickUpVenueContentGroup = {
    [locale in Locale]: PickUpVenueContent;
};

// These venue IDs are reflected in (1) "Featured Venues" on the English
// homepage and (2) "ピックアップレストラン" on the Japanese homepage.
//
// When updating venue IDs here, (1) also reflect the updates in the `pickUp`
// section of home.graphql, then (2) run `yard codegen` to update generated.ts.
export const pickUpVenues: PickUpVenueContentGroup = {
    en: {
        '244966': {order: 3, suffix: '?intlink=jp-ICS-Top_PickUp_en_caenne'},
        '245273': {order: 4, suffix: '?intlink=jp-ICS-Top_PickUp_en_Tenjaku'},
        '245459': {
            order: 1,
            suffix: '?intlink=jp-ICS-Top_PickUp_en_Sushi_Mamoru',
        },
        '245461': {order: 2, suffix: '?intlink=jp-ICS-Top_PickUp_en_Unotoki'},
    },
    ja: {
        '244966': {order: 3, suffix: '?intlink=jp-ICS-Top_PickUp_caenne'},
        '245273': {order: 4, suffix: '?intlink=jp-ICS-Top_PickUp_Tenjaku'},
        '245459': {order: 1, suffix: '?intlink=jp-ICS-Top_PickUp_Sushi_Mamoru'},
        '245461': {order: 2, suffix: '?intlink=jp-ICS-Top_PickUp_Unotoki'},
    },
};
